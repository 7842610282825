import React from "react";
import blue_logo from "../../../assets/Group 11612@3x.png";
import gasscan from "../../../assets/Group 11667@2x.png";
import playstoreicon from "../../../assets/en_badge_web_generic@3x.png";

function Main() {
  return (
    <>
      <div className="main_section_div">
        <div className="main_body_div">
          <div className="left_body_div">
            <img className="gasscan" src={gasscan} id="" alt={gasscan} />
          </div>
          <div className="right_body_div">
            <img className="blue_logo" src={blue_logo} id="" alt={blue_logo} />
            <p>Download application</p>
            <img className="playstoreicon" src={playstoreicon} id="" alt={playstoreicon} />
          </div>
        </div>
        <div className="main_footer_div">
          <div className="inner_footer_div1">
            <p>
              <a href="/Privacy-Policy">Privacy Policy</a>
            </p>
            <hr />
            <p>
              <a href="/Terms-and-Condition">Terms and Condition</a>
            </p>
            <hr />
            <p>
              <a href="/Account-Deactivation">
                Account Deactivation
              </a>
            </p>
          </div>
          <div className="inner_footer_div2">
            <p>© Copyright by PSO</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Main;
