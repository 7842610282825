import {
  Box,
  Button,
  Card,
  IconButton,
  Input,
  InputAdornment,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import profile from "../../../assets/user_img.png";
import { useDispatch, useSelector } from "react-redux";
import signInImage from "../../../assets/via_promo_code.png";
import orderPlaced from "../../../assets/order_placed_icon.png";
import orderCompleted from "../../../assets/order_completed_icon.png";
import reward from "../../../assets/reward_earning_icon.png";
import {
  getInfluencerDashboard,
  getReferalLink,
} from "../../../redux/store/action/unauth-action/discounted_voucher-action/discounted_voucher-action";
import { toast } from "react-toastify";
import { CopyAllOutlined, Share } from "@mui/icons-material";

const InfluencerDashboard = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  console.log("isSmallScreen::::::::::: ", isMediumScreen);
  const { authuserdetail } = useSelector(({ authStates }) => authStates);
  const [inviteLink, setInviteLink] = useState("");
  const [inviteActive, setInviteActive] = useState(false);
  const dispatch = useDispatch();
  const { referalLink, influencerDashboardDetails } = useSelector(
    ({ discountedVoucherStates }) => discountedVoucherStates
  );
  console.log("influencerDashboardDetails:::::::::::: " , influencerDashboardDetails)

  const resHandler = (data) => {
    if (data) {
      // setInviteLink(
      //   `http://test.lpgblue.com.s3-website-us-east-1.amazonaws.com/SignUp?infID=${referalLink?.referralLink}`
      // );
      setInviteLink(
        `https://lpgblue.com/SignUp?infID=${referalLink?.referralLink}`
      );
    }
  };

  const resHandlerDashboard = (data) => {
    if (data) {
      // console.log("Dashbaord Details:::::: " , influencerDashboardDetails)
    }
  };

  useEffect(() => {
    dispatch(getReferalLink(authuserdetail?.customer?.customerId, resHandler));
    dispatch(
      getInfluencerDashboard(
        authuserdetail?.customer?.customerId,
        resHandlerDashboard
      )
    );
  }, []);

  const handleInviteLink = () => {
    dispatch(getReferalLink(authuserdetail?.customer?.customerId, resHandler));
    setInviteActive(true);
  };
  const handleInviteLinkClose = () => {
    setInviteActive(false);
  };

  // const handleCopy = () => {
  //   if (navigator?.clipboard?.writeText) {
  //     // Use Clipboard API if available
  //     navigator.clipboard
  //       .writeText(inviteLink)
  //       .then(() => {
  //         toast.success("Invite Code copied!");
  //       })
  //       .catch((error) => {
  //         toast.error("Failed to copy invite code.");
  //       });
  //   } else {
  //     // Fallback method for environments without Clipboard API
  //     const textArea = document.createElement("textarea");
  //     textArea.value = inviteLink;
  //     document.body.appendChild(textArea);
  //     textArea.select();
  //     try {
  //       document.execCommand("copy");
  //       toast.success("Invite Code copied!");
  //     } catch (error) {
  //       toast.error("Failed to copy invite code.");
  //     }
  //     document.body.removeChild(textArea);
  //   }
  // };

  const handleCopy = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(inviteLink).then(() => {
        toast.success("Invite Link copied!");
      }).catch((error) => {
        console.error("Clipboard copy failed:", error);
        fallbackCopy(inviteLink);
      });
    } else {
      fallbackCopy(inviteLink);
    }
  };
  
  const fallbackCopy = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    toast.success("Invite Link copied");
  };

  return (
    <Stack rowGap={5}>
      <Card
        sx={{
          padding: isSmallScreen
            ? 1
            : isMediumScreen
            ? 2
            : isLargeScreen
            ? 3
            : 5,
          borderRadius: 4,
          boxShadow: 4,
          paddingY: 5,
        }}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        // overflow="auto"
        // style={styles.css}
      >
        <Box maxWidth={"800px"} margin={"auto"}>
          <Box display={"flex"} alignItems={"center"} mb={4}>
            <Box sx={{ width: `${isSmallScreen ? "100px" : "200px"} ` }}>
              <img
                src={
                  authuserdetail?.base64Image
                    ? authuserdetail?.base64Image
                    : profile
                }
                alt=""
                width={"100%"}
              />
            </Box>
            <Box>
              <Box>
                <h3>{authuserdetail?.customer?.name}</h3>
              </Box>
              <Box>
                <p>
                  {authuserdetail?.customer?.email} {<br />}{" "}
                  {authuserdetail?.customer?.phone}
                </p>
              </Box>
            </Box>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box
              flex={1}
              borderBottom={2}
              borderRight={2}
              p={isSmallScreen ? 1 : isMediumScreen ? 2 : isLargeScreen ? 3 : 5}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={10}
              >
                <Box minWidth={!isSmallScreen ? "125px" : "125px"}>
                  {isSmallScreen && (
                    <Box my={2}>
                      <img
                        src={signInImage}
                        alt=""
                        width={55}
                        // width={isSmallScreen ? 50 : "auto"}
                        // height={isSmallScreen ? 35 : "auto"}
                      />
                    </Box>
                  )}
                  <h5>Sign-ins Via Promo Code</h5>
                </Box>
                {!isSmallScreen && (
                  <Box>
                    <img
                      src={signInImage}
                      alt=""
                      // width={isSmallScreen ? 50 : "auto"}
                      // height={isSmallScreen ? 35 : "auto"}
                    />
                  </Box>
                )}
              </Box>
              <Box
                color={"#4978b9"}
                textAlign={"right"}
                mt={5}
                mr={isSmallScreen ? 2 : 0}
              >
                <h3
                // style={isSmallScreen && { fontSize: "20px" }}
                >
                  {influencerDashboardDetails?.signUps || 0}
                </h3>
              </Box>
            </Box>
            <Box
              flex={1}
              p={isSmallScreen ? 1 : isMediumScreen ? 2 : isLargeScreen ? 3 : 5}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={10}
              >
                <Box minWidth={"125px"}>
                  {isSmallScreen && (
                    <Box my={2}>
                      <img src={orderPlaced} alt="" width={60} />
                    </Box>
                  )}
                  <h5>Order Placed</h5>
                </Box>
                {!isSmallScreen && (
                  <Box>
                    <img src={orderPlaced} alt="" />
                  </Box>
                )}
              </Box>
              <Box color={"#4978b9"} textAlign={"right"} mt={8} mr={isSmallScreen ? 2 : 0}>
                <h3>{influencerDashboardDetails?.ordersPlaced || 0}</h3>
              </Box>
            </Box>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            position={"relative"}
            top={-4}
          >
            <Box
              flex={1}
              p={isSmallScreen ? 1 : isMediumScreen ? 2 : isLargeScreen ? 3 : 5}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                // gap={10}
              >
                <Box minWidth={"125px"}>
                  {isSmallScreen && (
                    <Box my={2}>
                      <img
                        src={orderCompleted}
                        alt=""
                        width={50}
                        // width={isSmallScreen && 50}
                        // height={isSmallScreen && 35}
                      />
                    </Box>
                  )}
                  <h5>Order Completed</h5>
                </Box>
                {!isSmallScreen && (
                  <Box>
                    <img
                      src={orderCompleted}
                      alt=""
                      // width={isSmallScreen && 50}
                      // height={isSmallScreen && 35}
                    />
                  </Box>
                )}
              </Box>
              <Box color={"#4978b9"} textAlign={"right"} mt={5} mr={isSmallScreen ? 2 : 0}>
                <h3>{influencerDashboardDetails?.ordersCompleted || 0}</h3>
              </Box>
            </Box>
            <Box
              flex={1}
              borderTop={2}
              borderLeft={2}
              p={isSmallScreen ? 1 : isMediumScreen ? 2 : isLargeScreen ? 3 : 5}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                // gap={10}
              >
                <Box minWidth={"125px"}>
                  {isSmallScreen && (
                    <Box my={2}>
                      <img src={reward} alt="" width={50} />
                    </Box>
                  )}
                  <h5>Reward Earnings</h5>
                </Box>
                {!isSmallScreen && (
                  <Box>
                    <img src={reward} alt="" />
                  </Box>
                )}
              </Box>
              <Box color={"#4978b9"} textAlign={"right"} mt={5} mr={isSmallScreen ? 2 : 0}>
                <h3>{influencerDashboardDetails?.rewardEarnings || 0}</h3>
              </Box>
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            // alignItems={"center"}
            justifyContent={"space-between"}
          >
            {inviteActive && (
              <Box mt={5}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  variant="outlined"
                  value={inviteLink}
                  sx={{
                    "& .MuiInputBase-input": {
                      WebkitTextFillColor: "rgba(0 , 0 , 0 , 0.7)",
                      color: "black",
                      cursor: "auto",
                      width: "100%",
                      height: "15px",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#0A74B9",
                        // borderStyle: "dashed",
                      },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#0A74B9",
                      },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#0A74B9",
                        backgroundColor: "#0A74B9",
                        backgroundColor: "rgba(10, 116, 185, 0.2)",
                        borderWidth: 2,
                        width: "100%",
                      },
                    "& .MuiInputLabel-root": {
                      color: "rgba(0, 0, 0, 0.8)",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#0A74B9",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleCopy}>
                          <CopyAllOutlined />
                        </IconButton>
                      </InputAdornment>
                    ),
                    readOnly: true,
                  }}
                />
              </Box>
            )}
            {!inviteActive && <Box mt={5} textAlign={"center"}>
              <Button
                variant="contained"
                size="large"
                onClick={handleInviteLink}
              >
                Share Your Link
              </Button>
            </Box>}
            {inviteActive && <Box mt={5} textAlign={"center"}>
              <Button
                variant="contained"
                color="error"
                size="large"
                onClick={handleInviteLinkClose}
              >
                Cancel
              </Button>
            </Box>}
          </Box>
        </Box>
      </Card>
    </Stack>
  );
};

export default InfluencerDashboard;
