import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInfluencerCategories } from "../../../redux/store/action/unauth-action/discounted_voucher-action/discounted_voucher-action";
import {
  Box,
  Card,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Check } from "@mui/icons-material";
import Form1 from "./Form1";
import Form2 from "./Form2";
import { toast } from "react-toastify";

const BecomeAnInfluencer = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [displayForm, setDisplayForm] = useState(1);
  const { authuserdetail } = useSelector(({ authStates }) => authStates);
  const { influencerCategories } = useSelector(
    ({ discountedVoucherStates }) => discountedVoucherStates
  );
  const [formData, setFormData] = useState({
    name: authuserdetail?.customer?.name,
    email: authuserdetail?.customer?.email,
    phone: authuserdetail?.customer?.phone,
    description: "",
    area: "",
    socialMediaPlatformDetails: [
      {
        id: 1,
        platform: "",
        link: "",
        followers: "",
      },
    ],
    category: "",
    age: null,
    gender: "",
  });
  const resHandler = (data) => {
    if (data) {
      console.log("influencerCategories::::: ", influencerCategories);
      // setInviteLink(
      //   `http://test.lpgblue.com.s3-website-us-east-1.amazonaws.com/SignUp?infID=${referalLink?.referralLink}`
      // );
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getInfluencerCategories(authuserdetail?.customer?.customerId, resHandler)
    );
  }, []);

  const handleChange = (e, index, field) => {
    const { name, type, value } = e.target;
    const newValue =
      type === "number" ? (value === "" ? "" : Number(value)) : value;

    // Check for negative value
    if (type === "number" && newValue !== "" && newValue < 0) {
      toast.error("Number Field cannot be negative!");
      return; // Stop further execution
    }
    // const newValue = type === "number" ? Number(value) : value;

    // // Check for negative value
    // if (type === "number" && (newValue === null || newValue === "" || newValue < 0)) {
    //   toast.error("Number Field cannot be negative!");
    //   return; // Stop further execution
    // }

    if (index !== undefined && field !== undefined) {
      // Update specific field in social media platform details
      setFormData((prevData) => {
        const updatedPlatforms = [...prevData.socialMediaPlatformDetails];
        updatedPlatforms[index] = {
          ...updatedPlatforms[index],
          [field]: newValue,
        };
        return {
          ...prevData,
          socialMediaPlatformDetails: updatedPlatforms,
        };
      });
    } else {
      // Update general formData fields
      setFormData((prevData) => ({
        ...prevData,
        [name]: newValue,
      }));
    }
  };

  const handleFormChange = (value) => {
    setDisplayForm(parseInt(value));
  };
  return (
    <Stack rowGap={5}>
      <Card
        sx={{
          padding: isSmallScreen
            ? 1
            : isMediumScreen
            ? 2
            : isLargeScreen
            ? 3
            : 5,
          borderRadius: 4,
          boxShadow: 4,
        }}
      >
        <Box mt={3}>
          <Typography variant="h5" fontWeight={"bold"} textAlign={"center"}>
            Influencer Registration
          </Typography>
        </Box>
        <Box
          mt={3}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            width={isSmallScreen ? 50 : 70}
            height={isSmallScreen ? 50 : 70}
            borderRadius={50}
            bgcolor={"#FCCC2E"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            color={"white"}
            fontWeight={"bold"}
          >
            <Check />
          </Box>
          <Typography
            variant="h6"
            textAlign={"center"}
            mt={2}
            color={"#195389"}
          >
            Please Provide your information
          </Typography>
        </Box>
        {displayForm === 1 && (
          <Form1
            handleFormChange={handleFormChange}
            formData={formData}
            handleChange={handleChange}
          />
        )}
        {displayForm === 2 && (
          <Form2
            handleFormChange={handleFormChange}
            handleChange={handleChange}
            formData={formData}
            setFormData={setFormData}
          />
        )}
      </Card>
    </Stack>
  );
};

export default BecomeAnInfluencer;
