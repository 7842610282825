import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import cylinder_img from "../../../../assets/Group 11613@3x.png";
import feedback from "../../../../assets/feedback.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GetCustomerOrder,
  fetchFeedbackQuestions,
  updateFeedback,
} from "../../../../redux/store/action/unauth-action/order-action/order-action";
import dayjs from "dayjs";
import {
  Button,
  IconButton,
  Modal,
  Rating,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Loader from "../../../../Loader/Loader";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";

function Past() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { authuserdetail } = useSelector(({ authStates }) => authStates);
  const { orderList, feedbackQuestions } = useSelector(
    ({ orderStates }) => orderStates
  );

  const resHandler = (data) => {
    if (data) {
      // console.log("Feedback Questions Data", feedbackQuestions);
    }
  };

  useEffect(() => {
    dispatch(fetchFeedbackQuestions(resHandler));
    dispatch(
      GetCustomerOrder(authuserdetail?.customer?.customerId, resHandler)
    );
  }, [dispatch, authuserdetail?.customer?.customerId]);

  // const data = useMemo(
  //     () => (orderList?.orders ? orderList.orders.filter(row => row.status === 'Delivered') : []),
  //     [orderList?.orders]
  // );

  const data = useMemo(
    () =>
      orderList?.orders
        ? orderList.orders
            .filter(
              (row) =>
                row.status === "Delivered" || row.status === "RequestCompleted"
            )
            .sort((a, b) => a.id - b.id)
        : [],
    [orderList?.orders]
  );

  console.log("datadatadata: ", data);

  const columns = useMemo(
    () => [
      {
        accessorKey: "img",
        header: "",
        Cell: ({ row }) => (
          <div
            className="order_list_card_inner_div"
            style={{
              backgroundColor: "white",
              borderRadius: "100px",
              boxShadow: "0px 0px 5px lightgray",
              padding: 5,
            }}
          >
            <img src={cylinder_img} alt="" />
          </div>
        ),
      },
      {
        accessorKey: "id",
        header: "Order Id",
      },
      {
        accessorKey: "requiredDate",
        id: "requiredDate",
        header: "Date",
        // Cell: ({ row }) => (<p>{row.original?.currentScheduleStatus?.requiredDate}</p>),
        Cell: ({ row }) =>
          dayjs(row?.original?.currentScheduleStatus?.requiredDate).format(
            "DD-MM-YYYY"
          ),
      },
      {
        accessorKey: "status",
        header: "Status",
      },
      {
        accessorKey: "quantity",
        id: "quantity",
        header: "Quantity",
        Cell: ({ row }) => <p style={{ margin: 0 }}>{row.original.orderDetail?.qty}</p>,
      },
      {
        accessorKey: "detail",
        header: "Detail",
        Cell: ({ row }) =>
          row.original.isFeedGiven !== true ? (
            <div className="main_detail_button_holder">
              {console.log("rowStatus::::::::::: ", row)}
              {row?.original?.status === "Delivered" && (
                <button
                  style={{
                    width: "200px",
                    backgroundColor: "#195389",
                    cursor: "pointer",
                    border: "2px solid #195389",
                    color: "white",
                    borderRadius: "5px",
                    padding: 10,
                  }}
                  onClick={() => openModal(row)}
                >
                  Feedback
                </button>
              )}
            </div>
          ) : (
            <div
              style={{
                width: "200px",
                backgroundColor: "gray",
                cursor: "pointer",
                border: "2px solid gray",
                color: "white",
                borderRadius: "5px",
                padding: 10,
                textAlign: "center",
              }}
            >
              Feedback Submitted
            </div>
          ),
      },
    ],
    [cylinder_img]
  );

  const table = useMaterialReactTable({
    columns,
    data,
  });

  const [isLoading, setIsloading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [orderid, setOrderid] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [comments, setComments] = useState(null);

  function openModal(row) {
    setOrderid(row?.original?.id);
    console.log("row.original");
    console.log(row.original);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setOrderid(null);
    setValue(0);
    setAnswers([]);
    setSelectedValue([]);
    setComments(null);
  }

  const handleAnswerChange = (questionId, userAnswer) => {
    const existingAnswerIndex = answers.findIndex(
      (ans) => ans.questionId === questionId
    );
    const updatedAnswers = [...answers];
    const updatedSelectedValue = [...selectedValue];

    if (existingAnswerIndex !== -1) {
      updatedAnswers[existingAnswerIndex].userAnswer = userAnswer;
      updatedSelectedValue[existingAnswerIndex].userAnswer =
        userAnswer === "Yes" ? "Yes" : "No";
    } else {
      updatedAnswers.push({ questionId, userAnswer });
      updatedSelectedValue.push({
        questionId,
        userAnswer: userAnswer === "Yes" ? "Yes" : "No",
      });
    }

    console.log("updatedAnswers", updatedAnswers);
    console.log("updatedSelectedValue", updatedSelectedValue);

    setAnswers(updatedAnswers);
    setSelectedValue(updatedSelectedValue);
  };

  const updatefeedbackresHandler = (data) => {
    if (data) {
      dispatch(
        GetCustomerOrder(authuserdetail?.customer?.customerId, resHandler)
      );
      setIsloading(false);
      closeModal();
    }
  };

  const submitfeedback = () => {
    if (value === 0) {
      toast.error("Please enter a Rateing");
    } else if (
      answers.length < 1 ||
      answers.length < feedbackQuestions.length
    ) {
      toast.error(
        "Please answer all the questions before submitting feedback."
      );
    } else if (!comments) {
      toast.error("Please enter a Comment");
    } else {
      setIsloading(true);

      let formData = {
        review: "string",
        rate: Number(value),
        detailComment: comments,
        orderId: orderid,
        questionAnswers: answers,
      };

      dispatch(updateFeedback(formData, updatefeedbackresHandler));
    }
  };

  return (
    <>
      {isLoading ? <Loader /> : <></>}

      {/* Feedback Modal Start Here */}
      <Modal
        open={modalIsOpen}
        onRequestClose={closeModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className="main_account_deletion_div">
          <Typography
            id="modal-title"
            variant="h6"
            sx={{
              textAlign: "center",
              color: "#1591c7",
              fontWeight: "bold",
              fontSize: "30px",
              marginTop: "60px",
              marginBottom: "30px",
            }}
          >
            Feedback
            <p style={{ fontSize: "1rem", color: "gray" }}>
              Order ID {orderid}
            </p>
            <div>
              <img src={feedback} alt="" width={"20%"} />
            </div>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={closeModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#fff",
              backgroundColor: "#dc0000",
            }}
          >
            <CloseIcon />
          </IconButton>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Rating
              name="no-value"
              size="large"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <p style={{ fontSize: "1.2rem", color: "gray" }}>
              Please Provide Your Feedback
            </p>
          </div>

          <div>
            {feedbackQuestions && feedbackQuestions.length > 0 ? (
              feedbackQuestions.map((item, index) => {
                return (
                  <div key={index} className="main_question_holder_div">
                    <div className="inner_single_question_holder_div">
                      <p style={{ color: "#0A74B9", fontSize: "1.2rem" }}>
                        {item.question}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                          alignItems: "center",
                          gap: "1.5rem",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: ".5rem",
                          }}
                        >
                          <input
                            id={`yes${item.id}`}
                            type="radio"
                            name={item.id}
                            value="Yes"
                            checked={
                              selectedValue.find(
                                (ans) => ans.questionId === item.id
                              )?.userAnswer === "Yes"
                            }
                            onChange={() => handleAnswerChange(item.id, "Yes")}
                          />
                          <label
                            style={{ margin: "0" }}
                            htmlFor={`yes${item.id}`}
                          >
                            Yes
                          </label>
                        </div>
                        <div
                          htmlFor=""
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: ".5rem",
                          }}
                        >
                          <input
                            id={`no${item.id}`}
                            type="radio"
                            name={item.id}
                            value="No"
                            checked={
                              selectedValue.find(
                                (ans) => ans.questionId === item.id
                              )?.userAnswer === "No"
                            }
                            onChange={() => handleAnswerChange(item.id, "No")}
                          />
                          <label
                            style={{ margin: "0" }}
                            htmlFor={`no${item.id}`}
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No Questions Found</p>
            )}
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              style={{ width: "90%" }}
              id="outlined-multiline-static"
              label="Any Other Comments"
              multiline
              minRows={4}
              maxRows={8}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </div>

          <Stack direction="row" justifyContent="center" spacing={7} p={4}>
            <Button
              variant="contained"
              sx={{
                width: "200px",
                height: "50px",
                borderRadius: "20px",
                backgroundColor: "#195389",
                "&:hover": {
                  backgroundColor: "#195389",
                },
              }}
              onClick={submitfeedback}
            >
              Submit
            </Button>
          </Stack>
        </div>
      </Modal>
      {/* Feedback Modal End Here */}

      <MaterialReactTable table={table} />
    </>
  );
}

export default Past;
