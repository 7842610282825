// import axios from "axios"
import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import instance from "../../../../libraries-configuration/axios-config/axios-config";
import {
  GETCUSTOMERDETAILS,
  GETLOACTIONS,
  IS_URGENT_VIEW,
  IS_URGENT_VIEW_CLEAR,
  IS_URGENT_VIEW_STOP,
  OTP,
  SET_HIDE_ADDRESS_MODAL,
  SET_HIDE_ADDRESS_MODAL_CLEAR,
  SET_HIDE_ADDRESS_MODAL_STOP,
  SET_HIDE_DISCOUNT_VOUCHER_POPUP,
  SET_HIDE_DISCOUNT_VOUCHER_POPUP_CLEAR,
  SET_HIDE_DISCOUNT_VOUCHER_POPUP_STOP,
  SET_PREV_DISCOUNT_VOUCHER_LIST,
  SET_PREV_ORDER_LIST,
} from "../../reducer/auth-reducer/auth-reducer";
import apiCallMethods from "../../../../libraries-configuration/api-methods/api-methods";

let endPoints = {
  login: "/Auth/CustomerLogin",
  otp: "/Auth/CustomerOTPVerify",
  emaillogin: "/Auth/CustomerEmailPINVerify",
  emailotp: "/Auth/CustomerEmailVerify",
  emailresentotp: "/Auth/CustomerOTPEmailRegenerate",
  resetpin: "/Auth/RegeneratePIN",
  // signup: "/Auth/CustomerRegistrationV2",
  signup: "/Auth/CustomerRegistration",
  getcustomerdetails: "/Customer/GetCustomerDetailsByPhoneNumber/",
  accountdeleted: "/User/SoftDeleteAccount",
  getloactions: "/OpenRoutes/ListAll",
};

const loginUser = (formData, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.POST,
        url: endPoints.login,
        data: formData,
      });

      console.log("Login data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          resHandler(response);
          toast.success(data?.message);
        } else {
          resHandler(response);
          toast.error(response?.data?.message);
        }
      }
    } catch (error) {
      resHandler(error?.response?.data);
      console.log(error);
      toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const UserOtp = (formData, verifyresHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.POST,
        url: endPoints.otp,
        data: formData,
      });

      console.log("Otp data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          console.log("response DATA: ", response);

          toast.success(data?.message);
          verifyresHandler(response);

          localStorage.setItem("AuthToken", data?.data?.jwToken);
          localStorage.setItem("RefreshToken", data?.data?.refreshToken);

          dispatch({
            type: OTP,
            payload: data?.data,
          });

          setTimeout(() => {
            window.location.href = "/";
          }, 100);
        } else {
          verifyresHandler(response);
          toast.error(response?.data?.message);
        }
      }
    } catch (error) {
      // resHandler(error?.response?.data)
      verifyresHandler(error?.response?.data);
      console.log(error);
      toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const loginEmailUser = (formData, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.POST,
        url: endPoints.emaillogin,
        data: formData,
      });

      console.log("login Email User data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          console.log("response DATA: ", response);

          toast.success(data?.message);
          resHandler(response);

          localStorage.setItem("AuthToken", data?.data?.jwToken);
          localStorage.setItem("RefreshToken", data?.data?.refreshToken);

          dispatch({
            type: OTP,
            payload: data?.data,
          });

          setTimeout(() => {
            window.location.href = "/";
          }, 100);
        } else {
          resHandler(response);
          toast.error(response?.data?.message);
        }
      }
    } catch (error) {
      resHandler(error?.response?.data);
      console.log(error);
      toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const UserEmailOtp = (formData, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.POST,
        url: endPoints.emailotp,
        data: formData,
      });

      console.log("User Email Otp data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          toast.success(data?.message);
          resHandler(response);

          // localStorage.setItem("AuthToken", data?.data?.jwToken);
          // localStorage.setItem("RefreshToken", data?.data?.refreshToken);

          // dispatch({
          //     type: OTP,
          //     payload: data?.data
          // });

          window.location.href = "/Email-Signin";
        } else {
          resHandler(response);
          toast.error(response?.data?.message);
        }
      }
    } catch (error) {
      resHandler(error?.response?.data);
      console.log(error);
      toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const UserEmailResendOtp = (formData, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.POST,
        url: endPoints.emailresentotp,
        data: formData,
      });

      console.log("Otp data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          toast.success(data?.message);
          resHandler(response);

          // localStorage.setItem("AuthToken", data?.data?.jwToken);
          // localStorage.setItem("RefreshToken", data?.data?.refreshToken);

          // dispatch({
          //     type: OTP,
          //     payload: data?.data
          // });

          // window.location.href = "/";
        } else {
          resHandler(response);
          toast.error(response?.data?.message);
        }
      }
    } catch (error) {
      resHandler(error?.response?.data);
      console.log(error);
      toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const ResetPinUser = (formData, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.POST,
        url: endPoints.resetpin,
        data: formData,
      });

      console.log("Otp data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          toast.success(data?.message);
          resHandler(response);

          // localStorage.setItem("AuthToken", data?.data?.jwToken);
          // localStorage.setItem("RefreshToken", data?.data?.refreshToken);

          // dispatch({
          //     type: OTP,
          //     payload: data?.data
          // });

          setTimeout(() => {
            window.location.href = "/Email-Signin";
          }, 100);
        } else {
          resHandler(response);
          toast.error(response?.data?.message);
        }
      }
    } catch (error) {
      resHandler(error?.response?.data);
      console.log(error);
      toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const SignupUser = (formData, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.POST,
        url: endPoints.signup,
        data: formData,
      });

      console.log("Signup User data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          localStorage.setItem("email", formData?.email);
          localStorage.setItem("phoneNumber", formData?.phoneNumber);
          resHandler(response);
          // toast.success(data?.message);
          // setTimeout(() => {
          // window.location.href = '/otp'
          // }, 2000);
        } else {
          resHandler(response);
          toast.error(response?.data?.message);
        }
      }
    } catch (error) {
      resHandler(error?.response?.data);
      console.log(error);
      toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const GetCustomerDetails = (userId) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.GET,
        url: endPoints.getcustomerdetails + userId,
      });

      console.log("GetCustomerDetails User data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          // toast.success(data?.message)

          dispatch({
            type: GETCUSTOMERDETAILS,
            payload: data?.data,
          });
        } else {
          toast.error(response?.data?.message);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const LogoutUser = () => {
  return (dispatch) => {
    localStorage.clear();
    window.location.href = "/";
  };
};

const AccountDeleted = (formData, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.POST,
        url: endPoints.accountdeleted,
        data: formData,
      });

      console.log("AccountDeleted data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          resHandler(response);
          toast.success(data?.message);
        } else {
          resHandler(response);
          toast.error(response?.data?.message);
        }
      }
    } catch (error) {
      resHandler(error?.response?.data);
      console.log(error);
      toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const Getloactions = () => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.GET,
        url: endPoints.getloactions,
      });

      console.log("Getloactions User data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          // toast.success(data?.message)

          dispatch({
            type: GETLOACTIONS,
            payload: data?.data,
          });
        } else {
          toast.error(response?.data?.message);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const Isurgentview = (formData) => {
  return async (dispatch) => {
    dispatch({
      type: IS_URGENT_VIEW,
      payload: formData,
    });
  };
};

const Isurgentviewstop = (formData) => {
  return async (dispatch) => {
    dispatch({
      type: IS_URGENT_VIEW_STOP,
      payload: formData,
    });
  };
};

const Isurgentviewclear = (formData) => {
  return async (dispatch) => {
    dispatch({
      type: IS_URGENT_VIEW_CLEAR,
      payload: formData,
    });
  };
};

const setHideAddressModal = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_HIDE_ADDRESS_MODAL,
      payload: value,
    });
  };
};

const setHideAddressModalStop = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_HIDE_ADDRESS_MODAL_STOP,
      payload: value,
    });
  };
};

const setHideAddressModalClear = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_HIDE_ADDRESS_MODAL_CLEAR,
      payload: value,
    });
  };
};

const setPrevOrderList = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_PREV_ORDER_LIST,
      payload: value,
    });
  };
};

const setHideDiscountVoucherPopup = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_HIDE_DISCOUNT_VOUCHER_POPUP,
      payload: value,
    });
  };
};

const setHideDiscountVoucherPopupStop = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_HIDE_DISCOUNT_VOUCHER_POPUP_STOP,
      payload: value,
    });
  };
};

const setHideDiscountVoucherPopupClear = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_HIDE_DISCOUNT_VOUCHER_POPUP_CLEAR,
      payload: value,
    });
  };
};

const setPrevDiscountVoucherList = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_PREV_DISCOUNT_VOUCHER_LIST,
      payload: value,
    });
  };
};

export {
  loginUser,
  UserOtp,
  loginEmailUser,
  UserEmailOtp,
  UserEmailResendOtp,
  ResetPinUser,
  SignupUser,
  GetCustomerDetails,
  LogoutUser,
  AccountDeleted,
  Getloactions,
  Isurgentview,
  Isurgentviewstop,
  Isurgentviewclear,
  setHideAddressModal,
  setHideAddressModalStop,
  setHideAddressModalClear,
  setPrevOrderList,
  setHideDiscountVoucherPopup,
  setHideDiscountVoucherPopupStop,
  setHideDiscountVoucherPopupClear,
  setPrevDiscountVoucherList
};

<ToastContainer />; // Place the ToastContainer outside of functions
