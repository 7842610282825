/*Note: Auth Reducer ***/

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authuserdetail: null,
  authrefreshToken: null,
  authjwToken: null,
  userName: null,
  userId: null,
  addressList: null,
  getloactions: null,
  isUrgenttView: false,
  isUrgentViewStop: false,
  hideAddressModal: false,
  hideAddressModalStop: false,
  prevOrderList: [],
  hideDiscountVoucherPopup: false,
  hideDiscountVoucherPopupStop: false,
  prevVoucherList: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    OTP: (state, action) => {
      let { payload } = action;
      console.log("payload:", payload);
      state.authuserdetail = payload;
      state.authrefreshToken = payload?.refreshToken;
      state.authjwToken = payload?.jwToken;
      state.userName = payload?.userName;
      state.userId = payload?.userId;
    },

    GETCUSTOMERDETAILS: (state, action) => {
      const { payload } = action;
      console.log("payload:", payload);
      state.authuserdetail = payload;
      state.addressList = payload?.details;
    },

    GETLOACTIONS: (state, action) => {
      const { payload } = action;
      console.log("payload:", payload);
      state.getloactions = payload;
    },

    IS_URGENT_VIEW: (state, action) => {
      state.isUrgenttView = action.payload;
    },
    IS_URGENT_VIEW_STOP: (state, action) => {
      state.isUrgentViewStop = action.payload;
    },
    IS_URGENT_VIEW_CLEAR: (state, action) => {
      state.isUrgenttView = action.payload;
      state.isUrgentViewStop = action.payload;
    },
    SET_HIDE_ADDRESS_MODAL: (state, action) => {
      state.hideAddressModal = action.payload;
    },
    SET_HIDE_ADDRESS_MODAL_STOP: (state, action) => {
      state.hideAddressModalStop = action.payload;
    },
    SET_HIDE_ADDRESS_MODAL_CLEAR: (state, action) => {
      state.hideAddressModal = action.payload;
    },
    SET_PREV_ORDER_LIST: (state, action) => {
      state.prevOrderList = action.payload;
    },
    SET_HIDE_DISCOUNT_VOUCHER_POPUP: (state, action) => {
      state.hideDiscountVoucherPopup = action.payload;
    },
    SET_HIDE_DISCOUNT_VOUCHER_POPUP_STOP: (state, action) => {
      state.hideDiscountVoucherPopupStop = action.payload;
    },
    SET_HIDE_DISCOUNT_VOUCHER_POPUP_CLEAR: (state, action) => {
      state.hideDiscountVoucherPopup = action.payload;
    },
    SET_PREV_DISCOUNT_VOUCHER_LIST: (state, action) => {
      state.prevVoucherList = action.payload;
    },
  },
});

export const {
  OTP,
  GETCUSTOMERDETAILS,
  GETLOACTIONS,
  IS_URGENT_VIEW,
  IS_URGENT_VIEW_STOP,
  IS_URGENT_VIEW_CLEAR,
  SET_HIDE_ADDRESS_MODAL,
  SET_HIDE_ADDRESS_MODAL_STOP,
  SET_HIDE_ADDRESS_MODAL_CLEAR,
  SET_PREV_ORDER_LIST,
  SET_HIDE_DISCOUNT_VOUCHER_POPUP,
  SET_HIDE_DISCOUNT_VOUCHER_POPUP_STOP,
  SET_HIDE_DISCOUNT_VOUCHER_POPUP_CLEAR,
  SET_PREV_DISCOUNT_VOUCHER_LIST,
} = authSlice.actions;

export default authSlice.reducer;
