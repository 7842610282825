import React, { useEffect, useState } from 'react';
import './Wallet.css';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetCustomerWallet } from '../../../redux/store/action/unauth-action/wallet-action/wallet-action';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';



function Wallet() {
    const dispatch = useDispatch();

    const { authuserdetail, userName } = useSelector(({ authStates }) => authStates);
    const { walletList } = useSelector(({ walletStates }) => walletStates);

    const [groupedLogs, setGroupedLogs] = useState(null);

    const resHandler = (data) => {
        if (data) { }
    };

    useEffect(() => {
        dispatch(GetCustomerWallet(authuserdetail?.customer?.customerId, resHandler));
    }, [dispatch, authuserdetail?.customer?.customerId]);

    useEffect(() => {
        if (walletList) {
            let logs = [...walletList?.wallet?.inLogs ?? [], ...walletList?.wallet?.outLogs ?? []];
            logs.sort((a, b) => new Date(b?.at) - new Date(a?.at)); // Sort in descending order

            // Group logs by date
            let groupedLogs = {};
            logs.forEach(log => {
                const date = log?.at?.split('T')[0];
                if (!groupedLogs[date]) {
                    groupedLogs[date] = [];
                }
                groupedLogs[date].push(log);
            });
            setGroupedLogs(groupedLogs);
        }
    }, [walletList]);


    console.log("groupedLogs Data: ", groupedLogs);

    return (
        <>
            <div className='main_order_list_div'>

                <div className='main_order_list_tabs_section_div'>
                    <div> <Link to='/My-Profile'>My Profile</Link></div>
                    <div> <Link to='/order-list'>Order list</Link></div>
                    <div> <Link to='/manage-address'>Manage Address</Link></div>
                    {/* <div> <Link to='/Manage-Cards'>Manage Cards</Link></div> */}
                    <div className='order_list_tab_active'> <Link to='/wallet'>Wallet</Link></div>
                    <div> <Link to='/discount-voucher'>Discount Vouchers</Link></div>
                </div>

                <div className='main_order_list_cards_div'>

                    <div className='wallet_amount_div'>
                        <div className='inner_wallet_amount_div1'>
                            <div className='inner_wallet_amount_div1_username'>{userName}</div>
                            <div className='inner_wallet_amount_div1_wallet_balance'>Wallet Balance</div>
                            <div className='inner_wallet_amount_div1_wallet_amount'>Rs. {walletList?.wallet?.balance.toFixed(2)}</div>
                        </div>
                        {/* <div className='inner_wallet_amount_div1'>
                            <TextField
                                id="outlined-basic"
                                label="Enter Amount"
                                variant="outlined"
                                style={{ width: "100%" }}
                            />

                            <TextField
                                id="outlined-basic"
                                label="Enter Card Number"
                                variant="outlined"
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div className='main_add_address_save_button_div'>
                            <button>Verify</button>
                        </div> */}
                    </div>

                    {/* <div className='wallet_amount_div'>
                        <div>Balance: Rs. {walletList?.wallet?.balance}</div>
                    </div> */}

                    {groupedLogs && Object.entries(groupedLogs).map(([date, logs]) => (
                        <div key={date} style={{ width: "100%", display: "flex", flexDirection: "column", gap: "1rem" }}>
                            <div className='wallet_amount_date_div'>
                                <div>{dayjs(date).format('DD-MM-YYYY')}</div>
                                <hr />
                            </div>
                            {logs.map((log, index) => (
                                <div key={index} className='main_order_list_cards_inner_div'>
                                    <div>
                                        <div style={{ color: log.status.startsWith('Out') ? '#ff0000' : '#03a07b', fontWeight: "bold" }}>{(log?.status.startsWith('In')) ? ("Receipt") : ("Invoice")}</div>
                                        <div style={{ color: log.status.startsWith('Out') ? '#ff0000' : '#03a07b', fontSize: ".8rem" }}>{dayjs(log?.at).format('hh:mm a')}</div>
                                    </div>
                                    <div style={{ color: log.status.startsWith('Out') ? '#ff0000' : '#03a07b', fontWeight: "bold" }}>
                                        Rs. {log?.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} /=
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Wallet;