import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Box,
  Card,
  Typography,
  Stack,
  Button,
  Link,
} from "@mui/material";
import Group116683x from "../../../assets/Group 11668@3x.png";
import Group116672x from "../../../assets/Group 11667@2x.png";
import Group116003x from "../../../assets/Group 11600@3x.png";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useDispatch } from "react-redux";
import {
  UserOtp,
  loginUser,
} from "../../../redux/store/action/auth-action/auth-action";
import { toast } from "react-toastify";
import Loader from "../../../Loader/Loader";

const CustomOtpInput = ({ value, valueLength, onChange }) => {
  const inputsRef = useRef([]);

  const handleChange = (e, index) => {
    const newValue = e.target.value;
    if (/^\d$/.test(newValue)) {
      const updatedValue = value.split("");
      updatedValue[index] = newValue;
      onChange(updatedValue.join(""));
      if (index < valueLength - 1) {
        inputsRef.current[index + 1].focus();
      }
    } else if (newValue === "") {
      const updatedValue = value.split("");
      updatedValue[index] = "";
      onChange(updatedValue.join(""));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !value[index] && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
      {Array.from({ length: valueLength }).map((_, index) => (
        <input
          key={index}
          ref={(el) => (inputsRef.current[index] = el)}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          maxLength={1}
          value={value[index] || ""}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          className="otp_input"
          // disabled
        />
      ))}
    </Box>
  );
};

export const OtpScreen = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsloading] = useState(false);

  const phoneNumber = localStorage.getItem("phoneNumber");
  const firebasetoken = localStorage.getItem("firebasetoken");

  const [Otp, setOtp] = useState("0990");

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const resHandler = (data) => {
    if (data) {
      setIsloading(false);
      setCount(60); // Reset the countdown timer to 60 seconds
      setIsResendButtonDisabled(true); // Disable the resend button immediately after resending
    }
  };

  const verifyresHandler = (data) => {
    if (data) {
      setIsloading(false);
    }
  };

  const otp = () => {
    if (Otp === "") {
      toast.error("Please enter a OTP!");
    } else {
      setIsloading(true);

      let formData = {
        otpCode: String(Otp),
        phoneNumber: String(phoneNumber),
        fcmToken: firebasetoken || "string",
        // fcmToken: "string"
      };
      // console.log(formData);
      dispatch(UserOtp(formData, verifyresHandler));
    }
  };

  const resendotp = () => {
    const phoneNumber = localStorage.getItem("phoneNumber");

    let formData = {
      phoneNumber: phoneNumber,
      version: "Web",
    };

    dispatch(loginUser(formData, resHandler));
  };

  const [count, setCount] = useState(60); // Set initial countdown time to 60 seconds
  const [IsResendButtonDisabled, setIsResendButtonDisabled] = useState(true); // Set initial button state to enabled

  let timer;

  useEffect(() => {
    timer =
      count > 0
        ? setInterval(() => {
            setCount(count - 1);
            if (count === 1) {
              setIsResendButtonDisabled(false); // Enable the button when count reaches 0
            }
          }, 1000)
        : clearInterval(timer);

    return () => clearInterval(timer);
  }, [count]);

  return (
    <>
      {isLoading ? <Loader /> : <></>}

      <Box
        sx={{
          backgroundImage: `url('${Group116683x}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100dvh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: { xs: 2, sm: 4 },
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid
            item
            xs={6}
            md={6}
            lg={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: { xs: 4, md: 0 },
            }}
          >
            <img
              style={{
                maxHeight: { xs: 300, md: 624 },
                width: "100%",
                objectFit: "contain",
              }}
              loading="lazy"
              src={Group116672x}
              alt={Group116672x}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={7}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ maxWidth: 500, width: "100%", p: 2 }}>
              <Card sx={{ padding: 3, borderRadius: 4 }}>
                <Stack sx={{ p: { xs: 0, sm: 3 }, rowGap: { xs: 2, sm: 4 } }}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <img
                      className="blue_logo_img"
                      loading="lazy"
                      src={Group116003x}
                      alt={Group116003x}
                    />
                  </Box>

                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    columnGap={1}
                  >
                    <Typography
                      sx={{ fontSize: { xs: 25, sm: 30 } }}
                      variant="h4"
                      fontWeight={"bold"}
                      textAlign="center"
                    >
                      OTP Verification
                    </Typography>
                    <Typography fontSize="12px" color="#8c8e92">
                      Enter the OTP sent to {phoneNumber}
                    </Typography>
                  </Stack>
                  <CustomOtpInput
                    value={Otp}
                    valueLength={4}
                    onChange={handleChange}
                    disabled
                  />
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{
                      borderRadius: 5,
                      fontSize: { xs: 15, sm: 20 },
                      fontWeight: "bold",
                    }}
                    onClick={otp}
                  >
                    Verify
                  </Button>
                  <div style={{ textAlign: "center" }}>
                    Please wait for your verification code{" "}
                    <b style={{ color: "black", fontWeight: "700" }}>
                      00:{count < 10 ? `0${count}` : count}
                    </b>
                  </div>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    columnGap={1}
                  >
                    <Typography fontSize="15px">
                      Didn't recieve OTP? &nbsp;
                      <Link
                        component="a"
                        sx={{ textDecoration: "none", cursor: "pointer" }}
                      >
                        <button
                          className="Resend_button"
                          disabled={IsResendButtonDisabled}
                          onClick={resendotp}
                        >
                          Resend
                        </button>
                      </Link>
                    </Typography>
                  </Stack>
                </Stack>
              </Card>
            </Box>
          </Grid>
        </Grid>
        <Stack
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            // p: 1,
            // backgroundImage: "linear-gradient(360deg, rgba(21, 51, 115, 1) 0%, rgba(104, 169, 217, 1) 100%)",
          }}
        >
          {/* <Box>
            <Typography variant="body2" align="center" color="#fff">
              Powered by PSO
            </Typography>
          </Box> */}
          <div className="main_footer_div">
            <div className="inner_footer_div1">
              <p>
                <a href="/Privacy-Policy">Privacy Policy</a>
              </p>
              <hr />
              <p>
                <a href="/Terms-and-Condition">Terms and Condition</a>
              </p>
              <hr />
              <p>
                <a href="/Account-Deactivation">Account Deactivation</a>
              </p>
            </div>
            <div className="inner_footer_div2">
              <p>© Copyright by PSO</p>
            </div>
          </div>
        </Stack>
      </Box>
    </>
  );
};
