import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  discountedVouchersList: null,
  referrerVoucherData: null,
  referreeVoucherData: null,
};

const discountedVoucherSlice = createSlice({
  name: "discountedVoucher",
  initialState,
  reducers: {
    DISCOUNTED_VOUCHER: (state, action) => {
      const { payload } = action;
      console.log("payload:", payload);
      state.discountedVouchersList = payload;
    },
    REFERAL_LINK: (state, action) => {
      const { payload } = action;
      console.log("payload:", payload);
      state.referalLink = payload;
    },
    INFLUENCER_DASHBOARD_DETAILS: (state, action) => {
      const { payload } = action;
      console.log("payload:", payload);
      state.influencerDashboardDetails = payload;
    },
    INFLUENCER_CATEGORIES: (state, action) => {
      const { payload } = action;
      console.log("payload:", payload);
      state.influencerCategories = payload;
    },
    REFERRER_VOUCHER_DETAILS: (state, action) => {
      const { payload } = action;
      console.log("payload:", payload);
      state.referrerVoucherData = payload;
    },
    REFERREE_VOUCHER_DETAILS: (state, action) => {
      const { payload } = action;
      console.log("payload:", payload);
      state.referreeVoucherData = payload;
    },
  },
});

export const {
  DISCOUNTED_VOUCHER,
  REFERAL_LINK,
  INFLUENCER_DASHBOARD_DETAILS,
  INFLUENCER_CATEGORIES,
  REFERRER_VOUCHER_DETAILS,
  REFERREE_VOUCHER_DETAILS,
} = discountedVoucherSlice.actions;

export default discountedVoucherSlice.reducer;
