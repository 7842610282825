import React from "react";
import {
  Modal,
  Typography,
  IconButton,
  Box,
  Stack,
  Divider,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";

const OrderConfirmationModal = ({
  open,
  handleClose,
  OrderConfirmationdetails,
  orderPricingDetails,
  isUrgent,
  selectedVoucher,
  walletList,
  matchingPaymentMethod,
  CreateNewOrder,
}) => {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div
        className="main_modal_div"
        style={{ maxHeight: "90vh", overflow: "auto" }}
      >
        <Typography
          id="modal-title"
          variant="h6"
          sx={{
            textAlign: "center",
            color: "#1591c7",
            fontWeight: "bold",
            fontSize: "30px",
            marginBottom: "10px",
          }}
        >
          Order Confirmation
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
            backgroundColor: "#dc0000",
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box mt={1} mb={0}>
          <Stack direction="row" justifyContent="space-between" p={1}>
            <Typography sx={{ color: "#818181" }}>Item</Typography>
            <Typography sx={{ color: "#1591c7" }}>
              {OrderConfirmationdetails?.itemname}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" p={1}>
            <Typography sx={{ color: "#818181" }}>Quantity</Typography>
            <Typography sx={{ color: "#1591c7" }}>
              {OrderConfirmationdetails?.quantity}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" p={1}>
            <Typography sx={{ color: "#818181" }}>Current Delivery Date</Typography>
            <Typography sx={{ color: "#1591c7" }}>
              {dayjs(OrderConfirmationdetails?.deliverydate).format("DD-MM-YYYY")}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" p={1}>
            <Typography sx={{ color: "#818181" }}>No. of Days for Refill</Typography>
            <Typography sx={{ color: "#1591c7" }}>
              {OrderConfirmationdetails?.recurringorder} Days
            </Typography>
          </Stack>
          {selectedVoucher && (
            <Stack direction="row" justifyContent="space-between" p={1}>
              <Box position={"relative"}>
                <Box position={"absolute"} left={"110%"} top={-10}>
                  <Typography
                    border={1}
                    sx={{ color: "#818181", borderStyle: "dashed" }}
                    px={2}
                    py={0}
                    borderRadius={10}
                    variant="body2"
                  >
                    Voucher
                  </Typography>
                </Box>
                <Typography sx={{ color: "#818181" }}>
                  {selectedVoucher?.name}
                </Typography>
              </Box>
              <Typography sx={{ color: "orangered" }}>
                - Rs. {Math.floor(orderPricingDetails?.discountAmount)}
              </Typography>
            </Stack>
          )}
          <Stack direction="row" justifyContent="space-between" p={1}>
            <Typography sx={{ color: "#818181" }}>Delivery Charges</Typography>
            <Typography sx={{ color: "#1591c7" }}>
              Rs.{" "}
              {Math.max(Math.floor(orderPricingDetails?.deliveryCharges), 0)
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" p={1}>
            <Typography sx={{ color: "#818181" }}>Urgent Delivery Charges</Typography>
            <Typography sx={{ color: "#1591c7" }}>
              Rs.{" "}
              {isUrgent
                ? Math.max(
                    Math.floor(orderPricingDetails?.urgentDeliveryCharges),
                    0
                  )
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0}
            </Typography>
          </Stack>
          
          <Stack direction="row" justifyContent="space-between" p={1}>
            <Typography sx={{ color: "#818181" }}>Total Bill Amount</Typography>
            <Typography sx={{ color: "#1591c7" }}>
              Rs.{" "}
              {Math.max(Math.floor(orderPricingDetails?.total), 0)
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Typography>
          </Stack>
          {walletList?.wallet?.balance ? (
            <>
              <Divider variant="middle" flexItem />
              <Stack direction="row" justifyContent="space-between" p={1}>
                <Typography sx={{ color: "#818181" }}>Wallet</Typography>
                <Typography sx={{ color: "#1591c7" }}>
                  - Rs.{" "}
                  {walletList?.wallet?.balance
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>
              </Stack>
            </>
          ) : null}
          <Divider variant="middle" flexItem />
          <Stack direction="row" justifyContent="space-between" p={1}>
            <Typography fontWeight = {"bold"} sx={{ color: "#818181" }}>Net Payable</Typography>
            <Typography fontWeight = {"bold"} sx={{ color: "#1591c7" }}>
              Rs.{" "}
              {Math.max(
                Math.floor(
                  orderPricingDetails?.total -
                    Math.floor(orderPricingDetails?.discountAmount) -
                    walletList?.wallet?.balance
                ),
                0
              )
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" p={1}>
            <Typography sx={{ color: "#818181" }}>
              Remaining Wallet Balance
            </Typography>
            <Typography sx={{ color: "#1591c7" }}>
              Rs.{" "}
              {Math.floor(
                orderPricingDetails?.total -
                Math.floor(orderPricingDetails?.discountAmount) -
                  walletList?.wallet?.balance >
                  0
                  ? 0
                  : walletList?.wallet?.balance -
                      orderPricingDetails?.total -
                      Math.floor(orderPricingDetails?.discountAmount)
              )
                ?.toFixed(0)
                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Typography>
          </Stack>
          <Stack p={1}>
            <Typography sx={{ color: "#818181", fontSize: "15px" }}>
              Delivery Address
            </Typography>
            <Typography sx={{ color: "#1591c7" }}>
              {OrderConfirmationdetails?.address}
            </Typography>
          </Stack>
          <Stack p={1}>
            <Typography>Payment Mode</Typography>
            <Typography sx={{ color: "#1591c7", fontSize: "15px" }}>
              {matchingPaymentMethod?.paymentMethod}
            </Typography>
          </Stack>
        </Box>
        <Typography
          sx={{
            color: "#1591c7",
            fontSize: "17px",
            fontWeight: "bold",
            textAlign: "center",
            paddingBottom: "10px",
          }}
        >
          Do you want to place this order?
        </Typography>
        <Stack direction="row" justifyContent="center" spacing={3} p={0}>
          <Button
            variant="contained"
            sx={{
              width: "200px",
              height: "50px",
              borderRadius: "20px",
              backgroundColor: "green",
              "&:hover": {
                backgroundColor: "darkgreen",
              },
            }}
            onClick={CreateNewOrder}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "200px",
              height: "50px",
              borderRadius: "20px",
              backgroundColor: "red",
              "&:hover": {
                backgroundColor: "darkred",
              },
            }}
            onClick={handleClose}
          >
            No
          </Button>
        </Stack>
      </div>
    </Modal>
  );
};

export default OrderConfirmationModal;
