import "react-toastify/dist/ReactToastify.css";
import instance from "../../../../../libraries-configuration/axios-config/axios-config";
import apiCallMethods from "../../../../../libraries-configuration/api-methods/api-methods";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DISCOUNTED_VOUCHER,
  REFERAL_LINK,
  INFLUENCER_DASHBOARD_DETAILS,
  INFLUENCER_CATEGORIES,
  REFERRER_VOUCHER_DETAILS,
  REFERREE_VOUCHER_DETAILS,
} from "../../../reducer/unauth-reducer/discounted_vouchers-reducer/discounted_vouchers-reducer";

let endPoints = {
  getVouchersByCustomerId: "/Voucher/GetVouchersByCustomerId?CustomerId=",
  getCustomerReferalLink: "/Customer/CreateOrGetRefferalLink",
  getCustomerRefereeDashboardDetails:
    "/Customer/ListAllRefereeDashboardDetails?influencerId=",
  getInfluencerCategories: "/Customer/ListAllInfluencerCategories",
  postInfluencerRequest: "/Customer/CreateInfluencerRequest",
  getReferrerVoucherType: "/Voucher/GetVoucherDetailsByType?voucherType=",
  getReferreeVoucherType: "/Voucher/GetVoucherDetailsByType?voucherType=",
};

const getVouchersByCustomerId = (customerId, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.GET,
        url: endPoints.getVouchersByCustomerId + customerId,
      });

      console.log("Vouchers List data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          dispatch({
            type: DISCOUNTED_VOUCHER,
            payload: data?.data,
          });
          resHandler(response);
          // toast.success(data?.message)
        } else {
          dispatch({
            type: DISCOUNTED_VOUCHER,
            payload: [],
          });
          resHandler(response);
          // toast.error(response?.data?.message)
        }
      }
    } catch (error) {
      // resHandler(error?.response?.data)
      console.log(error);
      // toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const getReferalLink = (customerId, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.POST, // Change to POST if body is required
        url: endPoints.getCustomerReferalLink,
        data: { customerId }, // Add customerId to the body
      });

      console.log("Referal Link : ", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          dispatch({
            type: REFERAL_LINK,
            payload: data?.data,
          });
          resHandler(response);
          // toast.success(data?.message)
        } else {
          resHandler(response);
          // toast.error(response?.data?.message)
        }
      }
    } catch (error) {
      // resHandler(error?.response?.data)
      console.log(error);
      // toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const getInfluencerDashboard = (customerId, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.GET, // Change to POST if body is required
        url: endPoints.getCustomerRefereeDashboardDetails + customerId,
        // data: { customerId }, // Add customerId to the body
      });

      console.log("Dashboard Details : ", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          dispatch({
            type: INFLUENCER_DASHBOARD_DETAILS,
            payload: data?.data,
          });
          resHandler(response);
          // toast.success(data?.message)
        } else {
          resHandler(response);
          // toast.error(response?.data?.message)
        }
      }
    } catch (error) {
      // resHandler(error?.response?.data)
      console.log(error);
      // toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const getInfluencerCategories = (customerId, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.GET, // Change to POST if body is required
        url: endPoints.getInfluencerCategories,
        // data: { customerId }, // Add customerId to the body
      });

      console.log("Dashboard Details : ", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          dispatch({
            type: INFLUENCER_CATEGORIES,
            payload: data?.data,
          });
          resHandler(response);
          // toast.success(data?.message)
        } else {
          resHandler(response);
          // toast.error(response?.data?.message)
        }
      }
    } catch (error) {
      // resHandler(error?.response?.data)
      console.log(error);
      // toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const becomeInfluencerRequest = (payload, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.POST, // Change to POST if body is required
        url: endPoints.postInfluencerRequest,
        data: payload, // Add customerId to the body
      });

      console.log("Creating Inluencer Response : ", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          // dispatch({
          //   type: REFERAL_LINK,
          //   payload: data?.data,
          // });
          resHandler(response);
          // toast.success(data?.message)
        } else {
          resHandler(response);
          // toast.error(response?.data?.message)
        }
      }
    } catch (error) {
      // resHandler(error?.response?.data)
      console.log(error);
      // toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const getReferrerVoucherDetails = (referrerVoucherType, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.GET,
        url: endPoints.getReferrerVoucherType + referrerVoucherType,
      });

      console.log("Vouchers List data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          dispatch({
            type: REFERRER_VOUCHER_DETAILS,
            payload: data?.data,
          });
          resHandler(response);
          // toast.success(data?.message)
        } else {
          dispatch({
            type: REFERRER_VOUCHER_DETAILS,
            payload: null,
          });
          resHandler(response);
          // toast.error(response?.data?.message)
        }
      }
    } catch (error) {
      // resHandler(error?.response?.data)
      console.log(error);
      // toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const getReferreeVoucherDetails = (referreeVoucherType, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.GET,
        url: endPoints.getReferreeVoucherType + referreeVoucherType,
      });

      console.log("Vouchers List data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          dispatch({
            type: REFERREE_VOUCHER_DETAILS,
            payload: data?.data,
          });
          resHandler(response);
          // toast.success(data?.message)
        } else {
          dispatch({
            type: REFERREE_VOUCHER_DETAILS,
            payload: null,
          });
          resHandler(response);
          // toast.error(response?.data?.message)
        }
      }
    } catch (error) {
      // resHandler(error?.response?.data)
      console.log(error);
      // toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

export {
  getVouchersByCustomerId,
  getReferalLink,
  getInfluencerDashboard,
  getInfluencerCategories,
  becomeInfluencerRequest,
  getReferrerVoucherDetails,
  getReferreeVoucherDetails,
};

<ToastContainer />; // Place the ToastContainer outside of functions
