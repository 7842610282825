import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { getVouchersByCustomerId } from "../../../redux/store/action/unauth-action/discounted_voucher-action/discounted_voucher-action";
import { FaTags } from "react-icons/fa";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Close, Lock } from "@mui/icons-material";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DiscountVoucher = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { selectaddress } = useSelector(({ addressStates }) => addressStates);
  const { authuserdetail } = useSelector(({ authStates }) => authStates);
  const { discountedVouchersList } = useSelector(
    ({ discountedVoucherStates }) => discountedVoucherStates
  );

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const referrerVoucher = discountedVouchersList?.filter(
    (voucher) =>
      (voucher.voucherType === "Referrer" ||
        voucher.voucherType === "Referee" ||
        voucher.voucherType === "InfluencerReferee") &&
      (voucher.applicableRegion === selectaddress.city ||
        voucher.applicableRegion === null)
  );
  const refereeVoucher = discountedVouchersList?.filter(
    (voucher) =>
      voucher.voucherType === "Referee" &&
      voucher.applicableRegion === selectaddress.city
  );
  const influencerVoucher = discountedVouchersList?.filter(
    (voucher) =>
      voucher.voucherType === "InfluencerReferee" &&
      voucher.applicableRegion === selectaddress.city
  );
  const discountVoucher = discountedVouchersList?.filter(
    (voucher) =>
      (voucher.voucherType === "Discount" ||
        voucher.voucherType === "SignUp") &&
      (voucher.applicableRegion === selectaddress.city ||
        voucher.applicableRegion === null)
  );

  // const filteredDiscountedVouchers = (
  //   value === 0 ? discountVoucher : value === 1 ? referrerVoucher : []
  // )?.filter((voucher) => {
  //   return voucher?.id !== selectedVoucherId;
  // });

  const TabLabel = ({
    text,
    count,
    bgColor = "#0A74B9",
    textColor = "#fff",
  }) => {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        {text}
        <span
          style={{
            backgroundColor: bgColor,
            color: textColor,
            borderRadius: "2px",
            width: "24px",
            height: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "14px",
          }}
        >
          {count}
        </span>
      </div>
    );
  };

  function DontHaveVouchers() {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          padding: 2,
        }}
      >
        <Typography
          variant="h5"
          textAlign="center"
          sx={{
            color: "gray",
            width: "100%",
            fontSize: "4rem",
          }}
        >
          <FaTags />
        </Typography>
        <Typography
          variant="h5"
          textAlign="center"
          sx={{
            color: "gray",
            width: "100%",
            fontSize: "1.5rem",
          }}
        >
          Sorry! <br /> You don't have any voucher
        </Typography>
      </Box>
    );
  }

  // const RegionbasedFilterVouchers = discountedVouchersList.filter((voucher) => {
  //   // Assuming `applicableRegion` is supposed to match `city` from selectedAddress
  //   return voucher.applicableRegion === selectaddress.city;
  // });

  // console.log("discountedVouchersList:::::::::::: " , discountedVouchersList , authuserdetail , selectaddress , RegionbasedFilterVouchers)

  const dispatch = useDispatch();
  const resHandler = (data) => {
    if (data) {
    }
  };
  useEffect(() => {
    if (authuserdetail?.customer?.customerId) {
      dispatch(
        getVouchersByCustomerId(
          authuserdetail?.customer?.customerId,
          resHandler
        )
      );
    }
  }, [authuserdetail?.customer?.customerId, dispatch]);
  // const { authuserdetail } = useSelector(({ authStates }) => authStates);

  const navigate = useNavigate();

  const currentDate = new Date();
  const vouchers = (
    value === 0 ? discountVoucher : value === 1 ? referrerVoucher : []
  )?.map((data, index) => (
    <Grid item xs={12} lg={12} mb={2} key={index}>
      <Stack
        direction="row"
        width={"100%"}
        height={"130px"}
        // borderRadius={"20px"}
        // overflow={"hidden"}
        boxShadow={"1px 1px 10px gray"}
      >
        <Stack
          sx={{ backgroundColor: "#FCCC2E", position: "relative" }}
          direction="row"
          alignItems="center"
        >
          <Stack
            direction={"column"}
            justifyContent={"space-around"}
            alignItems={"center"}
            sx={{
              position: "absolute",
              left: "-10px",
              top: "10px",
              bottom: "10px",
              zIndex: 50,
            }}
          >
            {Array.from({ length: 4 }).map((_, index) => (
              <Box
                key={index}
                sx={{
                  backgroundColor: "white",
                  width: "17px",
                  height: "10px",
                  borderRadius: "50%",
                  // boxShadow:
                  //   "inset 1px 1px 5px gray , inset -1px -1px 5px white",
                }}
              ></Box>
            ))}
          </Stack>
          <Typography
            variant="h7"
            fontWeight="bold"
            sx={{ transform: "rotate(270deg)" }}
          >
            {data?.discountValue}% off
          </Typography>
        </Stack>
        <Stack
          flex={1}
          direction={"column"}
          justifyContent={"space-between"}
          px={isSmallScreen ? 1 : 4}
          pt={2}
          position={"relative"}
          bgcolor={
            new Date(data.applicableFrom) >= currentDate ? "lightgray" : "white"
          }
        >
          {new Date(data.applicableFrom) >= currentDate && (
            <Box position={"absolute"} top={4} right={5}>
              <Lock />
            </Box>
          )}
          <Stack flex={1} borderBottom={"2px dashed lightgray"}>
            <Typography
              variant="h7"
              color="#0A74B9"
              fontSize="20px"
              fontWeight={"bold"}
            >
              {data?.name}
            </Typography>
            <Typography variant="h7" color="#9b9b9b" fontSize="10px">
              Max Capped Amount {data?.discountCapAmount}
            </Typography>
            <Typography variant="h7" color="#9b9b9b" fontSize="10px" mb={1}>
              Min.order Rs {data?.minimumOrderAmount}
            </Typography>
          </Stack>
          <Stack flex={1} alignItems={"center"} direction={"row"}>
            {!isSmallScreen && (
              <Typography
                variant="h7"
                color="#0A74B9"
                fontSize="15px"
                fontWeight={"bold"}
                textAlign={"end"}
              >
                Voucher Applicable from{" "}
                {moment(data?.applicableFrom).format("DD/MM/YY")} to{" "}
                {moment(data?.applicableTo).format("DD/MM/YY")}
              </Typography>
            )}
            {isSmallScreen && (
              <Typography
                variant="h6"
                color="#0A74B9"
                fontSize="10px"
                fontWeight={"bold"}
                textAlign={"end"}
              >
                {moment(data?.applicableFrom).format("DD/MM/YY")} to{" "}
                {moment(data?.applicableTo).format("DD/MM/YY")}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Grid>
  ));
  return (
    <>
      <div className="main_order_list_div">
        <div className="main_order_list_tabs_section_div">
          <div>
            {" "}
            <Link to="/My-Profile">My Profile</Link>
          </div>
          <div>
            {" "}
            <Link to="/order-list">Order list</Link>
          </div>
          <div>
            {" "}
            <Link to="/manage-address">Manage Address</Link>
          </div>
          {/* <div>
            {" "}
            <Link to="/Manage-Cards">Manage Cards</Link>
          </div> */}
          <div>
            {" "}
            <Link to="/wallet">Wallet</Link>
          </div>
          <div className="order_list_tab_active">
            {" "}
            <Link to="/discount-voucher">Discount Vouchers</Link>
          </div>
        </div>

        <Stack rowGap={5}>
          <Card
            sx={{
              padding: 3,
              borderRadius: 4,
              boxShadow: 4,
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              {/* <Grid container spacing={2}>
                {discountedVouchersList?.length > 0 ? (
                  vouchers
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      padding: 2,
                    }}
                  >
                    <Typography
                      variant="h5"
                      textAlign="center"
                      sx={{
                        color: "gray",
                        width: "100%",
                        fontSize: "4rem",
                      }}
                    >
                      <FaTags />
                    </Typography>
                    <Typography
                      variant="h5"
                      textAlign="center"
                      sx={{
                        color: "gray",
                        width: "100%",
                        fontSize: "1.5rem",
                      }}
                    >
                      Sorry! <br /> You don't have any voucher
                    </Typography>
                  </Box>
                )}
              </Grid> */}
              <Grid container spacing={2}>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      variant="fullWidth"
                    >
                      <Tab
                        label={
                          <TabLabel
                            text="Voucher"
                            count={discountVoucher?.length}
                          />
                        }
                        style={{ textTransform: "capitalize" }}
                        {...a11yProps(0)}
                      />
                      <Tab
                        label={
                          <TabLabel
                            text="Referral Voucher"
                            count={referrerVoucher?.length}
                          />
                        }
                        style={{ textTransform: "capitalize" }}
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </Box>
                  <CustomTabPanel
                    value={value}
                    index={0}
                    // style={{ padding: "10px" }}
                  >
                    {vouchers?.length > 0 ? vouchers : <DontHaveVouchers />}
                  </CustomTabPanel>
                  <CustomTabPanel
                    value={value}
                    index={1}
                    // style={{ padding: "10px" }}
                  >
                    {vouchers?.length > 0 ? vouchers : <DontHaveVouchers />}
                  </CustomTabPanel>
                </Box>
              </Grid>
            </Box>
          </Card>
        </Stack>
      </div>
    </>
  );
};

export default DiscountVoucher;
