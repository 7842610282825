import React from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Avatar,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { toast } from "react-toastify";
const Form1 = ({ handleChange, handleFormChange, formData }) => {
  const handleSubmit = (value) => {
    if(!formData.description) return toast.error("Please enter a description")
    if(!formData.area) return toast.error("Please enter your area")
    handleFormChange(value);
  };
  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // p: 3,
          backgroundColor: "#fff",
        }}
      >
        {/* Display-only Fields */}
        <TextField
          name="name"
          label="Name"
          value={formData.name}
          variant="outlined"
          disabled
          fullWidth
          margin="normal"
        />

        <TextField
          name="email"
          label="Email Address"
          value={formData.email}
          variant="outlined"
          disabled
          fullWidth
          margin="normal"
        />

        <TextField
          name="phone"
          label="Phone Number"
          value={formData.phone}
          variant="outlined"
          disabled
          fullWidth
          margin="normal"
        />

        {/* Editable Fields */}
        <TextField
          name="description"
          label="Describe Yourself"
          placeholder="Describe Yourself"
          variant="outlined"
          fullWidth
          margin="normal"
          multiline
          value={formData.description}
          required
          rows={4}
          onChange={handleChange}
        />

        <TextField
          name="area"
          label="Area/City"
          placeholder="Area/City"
          variant="outlined"
          fullWidth
          required
          value={formData.area}
          margin="normal"
          onChange={handleChange}
        />

        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2, width: "100%" }}
          onClick={() => handleSubmit(2)}
        >
          Next
        </Button>
      </Box>
    </Container>
  );
};

export default Form1;
