import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import cylinder_img from "../../../../assets/Group 11613@3x.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  CancelRecurringOrder,
  CancelSubscription,
  CreateRecurringOrder,
  DigiCashOTPVerify,
  DigiCashRegenerateOTP,
  GetCustomerOrder,
  GetCustomerScheduledOrder,
  GetOrderPricingDetails,
} from "../../../../redux/store/action/unauth-action/order-action/order-action";
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Modal,
  Stack,
  Typography,
  TextField,
  useMediaQuery,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FaCalendarAlt } from "react-icons/fa";
import Loader from "../../../../Loader/Loader";
import dayjs from "dayjs";
import checkedcylinder from "../../../../../src/assets/checkedcylinder.png";
import { toast } from "react-toastify";
import { MuiOtpInput } from "mui-one-time-password-input";
import DiscountedVoucherModal from "../../../../Modal/DiscountedVoucherModal";
import UrgentDCModal from "../../../../Modal/UrgentDCModal";
import {
  Isurgentview,
  Isurgentviewstop,
} from "../../../../redux/store/action/auth-action/auth-action";
import { getVouchersByCustomerId } from "../../../../redux/store/action/unauth-action/discounted_voucher-action/discounted_voucher-action";
import VoucherCard from "../../../../components/VoucherCard";
import NextOrderConfirmationModal from "../../../../components/Modals/NextOrderConfirmationModal";
function Next_orders() {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(false);

  const { selectaddress } = useSelector(({ addressStates }) => addressStates);
  const { authuserdetail, isUrgenttView, isUrgentViewStop } = useSelector(
    ({ authStates }) => authStates
  );
  const { walletList } = useSelector(({ walletStates }) => walletStates);
  const { discountedVouchersList } = useSelector(
    ({ discountedVoucherStates }) => discountedVoucherStates
  );
  const {
    paymentmethodslist,
    currentprices,
    createorderdetail,
    itemlist,
    scheduledorderList,
    recuringorderconfirmedlist,
    orderPricingDetails,
  } = useSelector(({ orderStates }) => orderStates);

  const [open, setOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState("");
  const [RowData, setRowData] = useState(null);
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [VoucherDiscount, setVoucherDiscount] = useState(null);
  const [Delivery_Charges, setDelivery_Charges] = useState(null);
  const [Urgent_Delivery_Charges, setUrgent_Delivery_Charges] = useState(0);
  const [isUrgent, setIsUrgent] = useState(false);

  const FilterObject = currentprices.find(
    (price) => price.city === selectaddress.city
  );

  //! Urgent Pop up Logic Start

  const handleStopIsUrgentView = () => {
    // dispatch(IS_URGENT_VIEW(false));
    // dispatch(IS_URGENT_VIEW_STOP(false));
    dispatch(Isurgentview(false));
    // dispatch(Isurgentviewstop(false));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("isUrgentStop>>>>>>>>>>>>>>>>>>>>> ", isUrgentViewStop);
      if (isUrgentViewStop === undefined || isUrgentViewStop === false) {
        // alert("i am here");
        setTimeout(() => {
          dispatch(Isurgentview(true));
          dispatch(Isurgentviewstop(true));
        }, 1000);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  //! Urgent Pop up Logic end

  //! Delivery Type Logic Start
  const handleDeliveryType = (charges) => {
    if (isUrgent === false) {
      setIsUrgent(true);
      setUrgent_Delivery_Charges(
        FilterObject?.urgentDeliveryCharges * RowData?.qty
      );
      setRowData((prev) => {
        return {
          ...prev,
          amount: prev?.amount + charges * RowData?.qty,
        };
      });
    } else {
      setIsUrgent(false);
      setUrgent_Delivery_Charges(0);
      setRowData((prev) => {
        return {
          ...prev,
          amount: prev?.amount - charges * prev?.qty,
        };
      });
    }
  };
  //! Delivery Type Logic End

  useEffect(() => {
    if (currentprices && RowData) {
      const FilterObject = currentprices.find(
        (price) => price.city === selectaddress.city
      );
      setDelivery_Charges(FilterObject?.deliveryCharges * RowData?.qty);
      if (FilterObject) {
        dispatch(
          GetOrderPricingDetails({
            FilterObject,
            authuserdetail,
            quantity: RowData?.qty,
            isUrgent,
            invoiceType: "EmptyReturn",
            voucherId: selectedVoucher ? selectedVoucher?.id : null,
          })
        );
      }
      // setUrgent_Delivery_Charges(FilterObject?.urgentDeliveryCharges);
    }
  }, [RowData]);

  console.log(
    "Current City Object::::::::> ",
    orderPricingDetails,
    FilterObject
  );

  useEffect(() => {
    const date = new Date();
    const formattedDate = formatDate(date);
    setCurrentDate(formattedDate);
  }, []);

  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const handleDateChange = (e) => {
    setCurrentDate(e.target.value);
  };

  const handleOpen = (row) => {
    setRowData(row.original);
    console.log("row.original Data: ", row.original);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const resHandler = (data) => {
    if (data) {
      setIsloading(false);
      setOpen(false);
    }
  };

  const RecurringresHandlerw = (data) => {
    console.log("Badchod RowData:::::::::::: ", RowData, data);
    // data.data.data.orderId
    setRowData((prevState) => ({
      ...prevState,
      orderId: data?.data?.data?.orderId,
    }));
    if (data?.data?.statusCode === 200 || data?.data?.succeeded === true) {
      // console.log("i am here");
      dispatch(
        GetCustomerScheduledOrder(
          authuserdetail?.customer?.customerId,
          resHandler
        )
      );
      setdigicashresdata(data?.data?.data);
      setOpen(false);
      setConfirmationModal(true);
      handleClose();
      setIsloading(false);
    }
  };

  const RecurringresHandler = (data) => {
    console.log("Error in CreateOrder:", data?.data?.data);
    if (data?.data?.statusCode === 200) {
      setdigicashresdata(data?.data?.data);
      if (ShowCardNumber == true) {
        dispatch(
          GetCustomerScheduledOrder(
            authuserdetail?.customer?.customerId,
            resHandler
          )
        );
        setDigicashOtpModal(true);
        handleClose();
        setIsloading(false);
      } else {
        setConfirmationModal(true);
      }
    } else {
      setIsloading(false);
      setOpen(false);
    }
    setIsloading(false);
  };

  const ConformRecurringOrderFunction = (row) => {
    setConfirmationOpen(true);
    console.log("Conform Recurring Order Function");
    const RowData = row.original || row;
    setRowData(RowData);
    console.log(RowData);
    // dispatch(
    //   GetOrderPricingDetails({
    //     FilterObject,
    //     authuserdetail,
    //     quantity: row.original?.qty,
    //     isUrgent,
    //     invoiceType: "EmptyReturn",
    //     voucherId: selectedVoucher ? selectedVoucher?.id : null,
    //   })
    // );
    // console.log(
    //   "Current City Object::::::::> ",
    //   orderPricingDetails,
    //   FilterObject
    // );
    // setIsloading(true) RowData?.nextScheduleDate
    // setCurrentDate(RowData?.nextScheduleDate)

    // let formData = {
    //     "schedulerId": RowData?.recurringSchedulerId,
    //     "total": RowData?.total,
    //     "amount": RowData?.amount,
    //     "paymentMethodId": RowData?.paymentMethodId,
    //     "newRequiredDate": currentDate
    // };

    // dispatch(CreateRecurringOrder(formData, RecurringresHandler));
  };

  //? Discounted Voucher Functionality Start Here
  const [voucherOpen, setVoucherOpen] = useState(false);
  const handleVoucherOpen = () => {
    console.log("handleVoucherOpen logic Working");
    setVoucherOpen(true);
  };
  const handleVoucherClose = () => setVoucherOpen(false);
  //? Discounted Voucher Functionality Ends Here

  //? SelectVoucher Logic Starts Here
  const handleVoucherSelected = (data) => {
    setSelectedVoucher(data);
    // Save the current total bill to avoid issues with stale state
    let currentBill = RowData?.amount * RowData?.qty;

    if (selectedVoucher) {
      // Reset to original price if a voucher is already selected
      setRowData((prevState) => ({
        ...prevState,
        amount: CurrectCityObject?.price,
      })); // Reset to original price
      currentBill = CurrectCityObject?.price * RowData?.qty; // Keep track of reset price for further calculation
    }

    setSelectedVoucher(data);
    let discount = (data?.discountValue / 100) * currentBill;
    discount = Number(Math.floor(discount).toFixed(0));

    if (discount > data?.discountCapAmount) {
      setVoucherDiscount(data.discountCapAmount);
      setRowData((prevState) => ({
        ...prevState,
        amount: currentBill - data?.discountCapAmount,
      }));
    } else {
      setVoucherDiscount(discount);
      setRowData((prevState) => ({
        ...prevState,
        amount: currentBill - discount,
      }));
    }
    toast.success("Voucher Selected!");
    handleVoucherClose();
  };
  //? SelectVoucher Logic Ends Here

  //? RemoveVoucher Logic Starts Here
  const handleVoucherRemove = (data) => {
    handleVoucherClose();
    setSelectedVoucher(null);
    setRowData((prevState) => ({
      ...prevState,
      amount: CurrectCityObject?.price,
    }));
    setVoucherDiscount(0);
    toast.error("Voucher Removed!");
  };
  //? RemoveVoucher Logic Ends Here

  // useEffect(() => {
  //     if (currentDate) {

  //         const currentDateFormatted = dayjs(currentDate).format('DD-MM-YYYY');
  //         const nextScheduleDateFormatted = dayjs(RowData?.nextScheduleDate).format('DD-MM-YYYY');

  //         if (nextScheduleDateFormatted === currentDateFormatted) {
  //             setCurrentDate(RowData?.nextScheduleDate);
  //             // alert("i am here")
  //         }

  //     }
  // }, [currentDate])

  const CancelRecurringOrderFunction = (row) => {
    // console.log("Cancel Recurring Order Function");
    const RowData = row.original || row;
    setRowData(RowData);
    setcancelRowData(RowData);
    setcancelOpen(true);

    // console.log(RowData);
    // setIsloading(true)
    // let formData = {
    //     "scheduleId": RowData?.recurringSchedulerId,
    //     "customerId": RowData?.customerId,
    //     "isActive": false
    // };

    // dispatch(CancelRecurringOrder(formData, resHandler));
  };

  const data = useMemo(
    () =>
      (scheduledorderList || []).filter(
        (row) => row.isScheduleActive == true && row.status === "Delivered"
      ),
    [scheduledorderList]
  );
  console.log("data: ", data);

  const columns = useMemo(
    () => [
      {
        accessorKey: "orderId",
        header: "Order ID",
      },
      {
        accessorKey: "nextScheduleDate",
        id: "nextScheduleDate",
        header: "Date",
        Cell: ({ row }) => (
          <p style={{ margin: 0 }}>{dayjs(row?.original?.nextScheduleDate).format("MM-DD-YYYY")}</p>
        ),
      },
      {
        accessorKey: "qty",
        header: "Quantity",
      },
      {
        accessorKey: "orderId",
        header: "",
        size: 150,
        Cell: ({ row }) => {
          const nextScheduleDate = new Date(row.original.nextScheduleDate);
          const requiredDate = new Date(row.original.requiredDate);
          const currentDate = new Date();

          const timeDifference = nextScheduleDate - currentDate;
          const daysDifference = Math.floor(
            timeDifference / (1000 * 3600 * 24)
          );

          const showButton =
            (nextScheduleDate < requiredDate ||
              (nextScheduleDate > requiredDate && daysDifference <= 23)) &&
            row.original.status === "Delivered";

          return (
            <div className="main_scheduled_div">
              {showButton ? (
                <>
                  <button
                    style={{ backgroundColor: "#03a07b" }}
                    onClick={() => ConformRecurringOrderFunction(row)}
                  >
                    Confirm
                  </button>
                  <button
                    style={{ backgroundColor: "#195389" }}
                    onClick={() => handleOpen(row)}
                  >
                    Edit
                  </button>
                </>
              ) : (
                <div className="main_detail_button_holder">
                  <div className="main_detail_button_holder">
                    <button
                      style={{
                        width: "100%",
                        backgroundColor: "#C10100",
                        cursor: "pointer",
                        border: "2px solid #C10100",
                        color: "white",
                        borderRadius: "5px",
                        padding: 10,
                      }}
                      onClick={() => cancelhandleOpen(row)}
                    >
                      Cancel Subscription
                    </button>
                  </div>
                </div>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
  });

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const [cancelopen, setcancelOpen] = useState(false);
  const [cancelrowData, setcancelRowData] = useState(null);

  const cancelhandleOpen = (row) => {
    console.log("Row Data", row?.original);
    setcancelRowData(row?.original);
    setcancelOpen(true);
  };

  const cancelhandleClose = () => {
    setcancelOpen(false);
  };

  const CancelSubscriptionresHandler = (data) => {
    if (data) {
    }
  };

  const cancelSubscriptionResHandler = (data) => {
    if (data?.data?.statusCode === 200 || data?.data?.succeeded === true) {
      handleClose();
      cancelhandleClose();
      dispatch(
        GetCustomerScheduledOrder(
          authuserdetail?.customer?.customerId,
          CancelSubscriptionresHandler
        )
      );
      dispatch(
        GetCustomerOrder(
          authuserdetail?.customer?.customerId,
          CancelSubscriptionresHandler
        )
      );
      setIsloading(false);
    }
  };

  const cancelhandleYesClick = () => {
    setIsloading(true);
    const formData = {
      scheduleId: cancelrowData?.recurringSchedulerId,
      customerId: authuserdetail?.customer?.customerId,
      isActive: false,
    };
    // let formData = {
    //     "scheduleId": RowData?.recurringSchedulerId,
    //     "customerId": RowData?.customerId,
    //     "isActive": false
    // };

    dispatch(CancelSubscription(formData, cancelSubscriptionResHandler));
  };

  // Modals Function Start Here
  const [Confirmationopen, setConfirmationOpen] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [OrderConfirmationdetails, setOrderConfirmationdetails] =
    useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [ShowCardNumber, setShowCardNumber] = useState(false);
  const [CardNumber, setCardNumber] = useState(null);
  const [digicashOtpModal, setDigicashOtpModal] = useState(false);
  const [digicashresdata, setdigicashresdata] = useState(null);
  const [digicashOTP, setdigicashOTP] = useState("259168");
  const [CurrectCityObject, setCurrectCityObject] = useState(null);

  useEffect(() => {
    if (currentprices && selectaddress) {
      const FilterObject = currentprices.find(
        (price) => price.city === selectaddress.city
      );
      if (FilterObject) {
        console.log("FilterObject Data: ", FilterObject);
        setCurrectCityObject(FilterObject);
      }
    }
  }, [currentprices, selectaddress]);

  const handleConfirmationOpen = () => {
    const today = new Date();
    const twoDaysLater = new Date(today.setDate(today.getDate() + 2));
    const yyyy = twoDaysLater.getFullYear();
    const mm = String(twoDaysLater.getMonth() + 1).padStart(2, "0");
    const dd = String(twoDaysLater.getDate()).padStart(2, "0");
    const finalDate = `${yyyy}-${mm}-${dd}`;
  };

  const ConfirmationopenhandleClose = () => {
    setIsUrgent(false);
    setUrgent_Delivery_Charges(0);
    setSelectedVoucher(null);
    setVoucherDiscount(0);
    setShowCardNumber(false);
    setConfirmationOpen(false);
  };

  const Confirmationopenopenhandle = () => {
    // setConfirmationModal(true);
    // handleClose();
    setIsloading(true);

    let paidAmount;
    if (
      //   RowData?.total - RowData?.securityDeposit >
      //   walletList?.wallet?.balance
      orderPricingDetails?.total > walletList?.wallet?.balance
    ) {
      // paidAmount = Math.floor(walletList?.wallet?.balance);
      paidAmount = walletList?.wallet?.balance;
    } else {
      //   paidAmount = RowData?.total - RowData?.securityDeposit;
      // paidAmount = Math.floor(
      //   FilterObject?.price * RowData?.qty + Urgent_Delivery_Charges
      // );
      paidAmount = orderPricingDetails?.total;
    }

    let formData = {
      schedulerId: RowData?.recurringSchedulerId,
      //   total: RowData?.total - RowData?.securityDeposit,
      // total: Math.max(
      //   walletList?.wallet?.balance < FilterObject?.price * RowData?.qty
      //     ? Math.floor(
      //         FilterObject?.price * RowData?.qty +
      //           Urgent_Delivery_Charges -
      //           walletList?.wallet?.balance
      //       ).toFixed(0)
      //     : 0
      // ),
      // total: Math.max(
      //   Math.floor(
      //     FilterObject?.price * RowData?.qty + Urgent_Delivery_Charges
      //   ).toFixed(0)
      // ),
      total: orderPricingDetails?.total,
      price: orderPricingDetails?.price,
      // amount: Number(Math.floor(orderPricingDetails?.amount).toFixed(0)),
      price: orderPricingDetails?.price,
      amount: orderPricingDetails?.amount,
      paymentMethodId: RowData?.paymentMethodId,
      newRequiredDate: currentDate,
      customerDetailAddressId: Number(selectaddress?.id),
      paidAmount: Number(paidAmount),
      mid: "",
      cardNo: CardNumber,
      deviceId: navigator?.userAgent,
      currentPricingId: FilterObject?.currentPriceId,
      isUrgent: isUrgent,
      voucherId: selectedVoucher ? selectedVoucher?.id : null,
    };

    console.log("formData DATA: ", formData);

    dispatch(CreateRecurringOrder(formData, RecurringresHandlerw));
  };

  const [Otp, setOtp] = useState("");

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const handleConfirmationModalClose = () => {
    dispatch(
      getVouchersByCustomerId(authuserdetail?.customer?.customerId, resHandler)
    );
    // dispatch(
    //   GetCustomerWallet(authuserdetail?.customer?.customerId, resHandler)
    // );
    dispatch(
      GetCustomerScheduledOrder(
        authuserdetail?.customer?.customerId,
        resHandler
      )
    );
    dispatch(
      GetCustomerOrder(authuserdetail?.customer?.customerId, resHandler)
    );
    setShowCardNumber(false);
    setConfirmationOpen(false);
    setConfirmationModal(false);
    setSelectedVoucher(null);
    // navigate('/')
  };

  const matchingPaymentMethod = paymentmethodslist.find(
    (method) => method.id === Number(OrderConfirmationdetails?.paymentmethod)
  );

  // Calculate the number of days between the current date and the selected date
  const timeDifference =
    new Date(currentDate) - new Date(RowData?.nextScheduleDate);
  let daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
  daysDifference = daysDifference < 0 ? 0 : daysDifference;

  // const filteredPaymentMethods = paymentmethodslist.filter(
  //   (payment) => payment.id !== 3
  // );
  const filteredPaymentMethods = paymentmethodslist.filter(
    (payment) => payment.id === 1
  );

  const handlePaymentMethodChange = (event) => {
    console.log(event.target.value);
    if (event.target.value == 2) {
      setPaymentMethod(event.target.value);
      setShowCardNumber(true);
    } else {
      setPaymentMethod(event.target.value);
      setShowCardNumber(false);
    }
  };

  const DigiCashOTPVerifyresHandler = (data) => {
    console.log(
      "DigiCashOTPVerifyresHandler Data: ",
      data?.data?.data?.responseMessage
    );
    if (data?.data?.statusCode === 200) {
      toast.success(data?.data?.data?.responseMessage);
      handleClose();
      setIsloading(false);
      setDigicashOtpModal(false);
      setShowCardNumber(false);
      setConfirmationOpen(false);
      setConfirmationModal(true);
      setCardNumber(null);
    } else {
      setIsloading(false);
      setOpen(false);
    }
  };

  const handleVerifyClick = () => {
    setIsloading(true);
    let formData = {
      customerId: digicashresdata?.customerId,
      merchantTxn_Id: digicashresdata?.merchantTxn_Id,
      paymentMethod: "DigiCash",
      deviceId: navigator?.userAgent,
      otp: digicashOTP,
      trDateTime: "",
      paymentTransactionId: digicashresdata?.paymentTransactionId,
      orderId: digicashresdata?.orderId,
    };
    dispatch(DigiCashOTPVerify(formData, DigiCashOTPVerifyresHandler));
  };

  const [count, setCount] = useState(60); // Set initial countdown time to 60 seconds
  const [IsResendButtonDisabled, setIsResendButtonDisabled] = useState(true); // Set initial button state to enabled

  let timer;

  useEffect(() => {
    timer =
      count > 0
        ? setInterval(() => {
            setCount(count - 1);
            if (count === 1) {
              setIsResendButtonDisabled(false); // Enable the button when count reaches 0
            }
          }, 1000)
        : clearInterval(timer);

    return () => clearInterval(timer);
  }, [count]);

  const RegenerateDigiCashOTPresHandler = (data) => {
    if (data) {
      setIsloading(false);
      setCount(60); // Reset the countdown timer to 60 seconds
      setIsResendButtonDisabled(true); // Disable the resend button immediately after resending
    }
  };

  const RegenerateDigiCashOTP = () => {
    setIsloading(true);

    let formData = {
      orderID: Number(digicashresdata?.orderId),
    };

    dispatch(DigiCashRegenerateOTP(formData, RegenerateDigiCashOTPresHandler));
  };

  console.log(
    "Badchod Urgent_Delivery_Charges::::::::: ",
    FilterObject?.urgentDeliveryCharges * RowData?.qty,
    FilterObject?.price * RowData?.qty,
    walletList?.wallet?.balance,
    FilterObject?.price * RowData?.qty +
      Urgent_Delivery_Charges -
      walletList?.wallet?.balance >
      0
      ? FilterObject?.price * RowData?.qty +
          Urgent_Delivery_Charges -
          walletList?.wallet?.balance
      : 0
  );

  return (
    <>
      {isLoading ? <Loader /> : null}

      {isUrgenttView && (
        <UrgentDCModal handleStopIsUrgentView={handleStopIsUrgentView} />
      )}

      {/* Order Confirmation Modal Start Here */}
      <NextOrderConfirmationModal
        Confirmationopen={Confirmationopen}
        ConfirmationopenhandleClose={ConfirmationopenhandleClose}
        itemlist={itemlist}
        RowData={RowData}
        orderPricingDetails={orderPricingDetails}
        isSmallScreen={isSmallScreen}
        walletList={walletList}
        selectedVoucher={selectedVoucher}
        handleVoucherRemove={handleVoucherRemove}
        selectaddress={selectaddress}
        filteredPaymentMethods={filteredPaymentMethods}
        handlePaymentMethodChange={handlePaymentMethodChange}
        ShowCardNumber={ShowCardNumber}
        Confirmationopenopenhandle={Confirmationopenopenhandle}
        currentDate={currentDate}
        FilterObject={FilterObject}
        isUrgent={isUrgent}
        discountedVouchersList={discountedVouchersList}
        handleVoucherOpen={handleVoucherOpen}
        handleVoucherClose={handleVoucherClose}
        voucherOpen={voucherOpen}
        handleDeliveryType={handleDeliveryType}
        Urgent_Delivery_Charges={Urgent_Delivery_Charges}
        handleVoucherSelected= {handleVoucherSelected}
      />
      {/* Order Confirmation Modal End Here */}

      {/* Thank you for your purchase Modal Start Here */}
      <Modal
        open={confirmationModal}
        aria-labelledby="third-modal-title"
        aria-describedby="third-modal-description"
      >
        <div className="main_modal_div">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              className="blue_logo"
              src={checkedcylinder}
              alt="Order Placed"
              style={{ width: "100px", height: "100px" }}
            />
          </Box>
          <Typography
            id="third-modal-title"
            variant="h4"
            sx={{
              textAlign: "center",
              color: "#1591c7",
              fontWeight: "bold",
              fontSize: "23px",
            }}
          >
            Thank you for your purchase
          </Typography>
          <Typography
            id="third-modal-description"
            sx={{ textAlign: "center", fontSize: "17px" }}
          >
            Your order {RowData?.orderId} has been recieved
          </Typography>
          <Typography
            id="third-modal-description"
            sx={{ textAlign: "center", fontSize: "13px" }}
          >
            You will get a confirmation shortly
          </Typography>

          <Stack marginTop={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography color="#818181">Quantity</Typography>
              <Typography color="#1591c7">{RowData?.qty}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" marginTop={2}>
              <Typography color="#818181">Delivery Date</Typography>
              <Typography sx={{ color: "#1591c7" }}>
                {dayjs(currentDate).format("DD-MM-YYYY")}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" marginTop={2}>
              <Typography color="#818181">Payment Mode</Typography>
              <Typography color="#1591c7">{RowData?.paymentMethod}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" marginTop={2}>
              <Typography color="#818181">Total Bill Amount</Typography>
              {/* <Typography sx={{ color: "#1591c7" }}>Rs {CurrectCityObject?.priceRowData?.total !== null ? (RowData?.total - RowData?.securityDeposit).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0"}</Typography> */}
              {/* {selectedVoucher ? (
                <Typography sx={{ color: "#1591c7" }}>
                  Rs.{" "}
                  {RowData?.amount
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>
              ) : (
                <Typography sx={{ color: "#1591c7" }}>
                  Rs.{" "}
                  {Math.max(
                    CurrectCityObject?.price - walletList?.wallet?.balance,
                    0
                      ? FilterObject?.price * RowData?.qty +
                        FilterObject?.urgentDeliveryCharges * RowData?.qty -
                        walletList?.wallet?.balance
                      : 0
                    )
                      ?.toFixed(0)
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0"}
              </Typography> */}
              {/* <Typography sx={{ color: "#1591c7" }}>
                Rs.{" "}
                {FilterObject !== null
                  ? Math.floor(
                      FilterObject?.price * RowData?.qty +
                        Urgent_Delivery_Charges -
                        VoucherDiscount -
                        walletList?.wallet?.balance >
                        0
                        ? FilterObject?.price * RowData?.qty +
                            Urgent_Delivery_Charges -
                            VoucherDiscount -
                            walletList?.wallet?.balance
                        : 0
                    )
                      ?.toFixed(0)
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0"}
              </Typography> */}
              <Typography sx={{ color: "#1591c7" }}>
                Rs.{" "}
                {FilterObject !== null
                  ? Math.floor(
                      orderPricingDetails?.total -
                        Math.floor(orderPricingDetails?.discountAmount) -
                        walletList?.wallet?.balance >
                        0
                        ? orderPricingDetails?.total -
                        Math.floor(orderPricingDetails?.discountAmount) -
                            walletList?.wallet?.balance
                        : 0
                    )
                      ?.toFixed(0)
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0"}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" marginTop={2}>
              <Typography color="#818181">Delivery Address</Typography>
              <Stack>
                <Typography color="#1591c7">{selectaddress.address}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Typography
            sx={{ textAlign: "center", marginTop: "20px", fontSize: "20px" }}
          >
            Kindly show the QR code at the time of delivery
          </Typography>
          <Box mt={2} textAlign="center">
            <Button
              variant="contained"
              sx={{
                backgroundColor: "primary",
                width: "70%",
                height: "50px",
                borderRadius: "20px",
              }}
              onClick={handleConfirmationModalClose}
            >
              Back To Home
            </Button>
          </Box>
        </div>
      </Modal>
      {/* Thank you for your purchase Modal End Here */}

      {/* Rescheduled Modal Start Here */}
      <Modal
        open={open}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className="main_account_deletion_div">
          <Typography
            id="modal-title"
            variant="h6"
            sx={{
              textAlign: "center",
              color: "#1591c7",
              fontWeight: "bold",
              fontSize: "30px",
              marginBottom: "60px",
            }}
          ></Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#fff",
              backgroundColor: "#dc0000",
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            sx={{
              color: "#1591c7",
              fontSize: { xs: "1.8rem", sm: "1rem" },
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Your order is scheduled to be delivered on{" "}
            {dayjs(currentDate).format("DD-MM-YYYY")}
          </Typography>

          <Stack
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
            p={4}
          >
            <Button
              variant="contained"
              sx={{
                width: "200px",
                height: "50px",
                borderRadius: "20px",
                backgroundColor: "#195389",
              }}
              onClick={() => ConformRecurringOrderFunction(RowData)}
            >
              Confirm
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "200px",
                height: "50px",
                borderRadius: "20px",
                backgroundColor: "#195389",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              component="label"
            >
              Reschedule &nbsp; <FaCalendarAlt />
              <input
                id="reschedule"
                name="reschedule"
                type="date"
                value={currentDate}
                onChange={handleDateChange}
                min={getCurrentDate()}
                style={{
                  position: "absolute",
                  opacity: 0,
                  cursor: "pointer",
                }}
              />
            </Button>
          </Stack>

          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            p={2}
          >
            <Typography
              sx={{
                color: "black",
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Don't want to continue with this subscription?
            </Typography>
            <Typography
              sx={{
                color: "red",
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => CancelRecurringOrderFunction(RowData)}
            >
              Cancel Subscription
            </Typography>
          </Stack>
        </div>
      </Modal>
      {/* Rescheduled Modal End Here */}

      {/* Cancel Subscription Modal Start Here */}
      <Modal
        open={cancelopen}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 10,
          }}
        >
          <Typography
            id="modal-title"
            variant="h6"
            sx={{
              textAlign: "center",
              color: "#1591c7",
              fontWeight: "bold",
              fontSize: "30px",
              marginBottom: "60px",
            }}
          ></Typography>
          <IconButton
            aria-label="close"
            onClick={cancelhandleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#fff",
              backgroundColor: "#dc0000",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{
              color: "#1591c7",
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Do you want to cancel this Subscription?
          </Typography>
          <Stack direction="row" justifyContent="center" spacing={5} p={4}>
            <Button
              variant="contained"
              sx={{
                width: "200px",
                height: "50px",
                borderRadius: "20px",
                backgroundColor: "green",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
              }}
              onClick={cancelhandleYesClick}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "200px",
                height: "50px",
                borderRadius: "20px",
                backgroundColor: "red",
                "&:hover": {
                  backgroundColor: "darkred",
                },
              }}
              onClick={cancelhandleClose}
            >
              No
            </Button>
          </Stack>
        </Card>
      </Modal>
      {/* Cancel Subscription Modal End Here */}

      {/* DIGICASH Modal Start Here */}
      <Modal
        open={digicashOtpModal}
        aria-labelledby="second-modal-title"
        aria-describedby="second-modal-description"
      >
        <div className="main_modal_div">
          <Typography
            id="second-modal-title"
            variant="h3"
            sx={{ textAlign: "center", color: "#2b499a", fontWeight: "bold" }}
          >
            DIGICASH
          </Typography>
          <Typography
            id="second-modal-description"
            variant="h5"
            sx={{ textAlign: "center", color: "#2b499a", fontWeight: "bold" }}
          >
            Card Number Verification
          </Typography>
          <Typography
            id="second-modal-description"
            sx={{ textAlign: "center", color: "#2b499a", fontSize: "25px" }}
          >
            Enter the OTP sent to +923362203426
          </Typography>
          <MuiOtpInput
            value={Otp}
            // onChange={handleChange}
            length={6}
            p={6}
            sx={{ borderRadius: "20px" }}
            // value={digicashOTP}
            onChange={(e) => setdigicashOTP(e.target.value)}
          />
          <Typography sx={{ textAlign: "center", color: "#2b499a" }}>
            Please wait for your verification code 00:
            {count < 10 ? `0${count}` : count}
          </Typography>
          <Stack direction="row" justifyContent="center">
            <Typography
              sx={{ textAlign: "center", color: "#2b499a", fontSize: "20px" }}
            >
              Didn't receive OTP?
            </Typography>
            <Button
              sx={{
                color: "#2b499a",
              }}
              disabled={IsResendButtonDisabled}
              onClick={RegenerateDigiCashOTP}
            >
              Resend
            </Button>
          </Stack>
          <Box mt={4} textAlign="center">
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#2d4c9c",
                width: "70%",
                height: "50px",
                borderRadius: "20px",
              }}
              onClick={handleVerifyClick}
            >
              Verify
            </Button>
          </Box>
        </div>
      </Modal>
      {/* DIGICASH Modal End Here */}

      <MaterialReactTable table={table} />
    </>
  );
}

export default Next_orders;
