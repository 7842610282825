import React from "react";
import { Stack, Typography, Box, Button } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

const VoucherCard = ({
  discountValue,
  name,
  discountAmount,
  onRemove,
  isSmallScreen,
}) => {
  return (
    <Stack
      direction="row"
      width={"100%"}
      height={isSmallScreen? "155px" : "140px"}
      boxShadow={"0px 0px 5px #ecdcdc"}
    >
      <Stack
        sx={{
          backgroundColor: "#FCCC2E",
          position: "relative",
        }}
        direction="row"
        alignItems="center"
      >
        <Stack
          direction={"column"}
          justifyContent={"space-around"}
          alignItems={"center"}
          sx={{
            position: "absolute",
            left: "-10px",
            top: "10px",
            bottom: "10px",
            zIndex: 50,
          }}
        >
          {Array.from({ length: 4 }).map((_, index) => (
            <Box
              key={index}
              sx={{
                backgroundColor: "white",
                width: "17px",
                height: "10px",
                borderRadius: "50%",
              }}
            ></Box>
          ))}
        </Stack>
        <Typography
          variant="h7"
          fontWeight="bold"
          sx={{ transform: "rotate(270deg)" }}
        >
          {discountValue}% off
        </Typography>
      </Stack>
      <Stack
        flex={1}
        direction={"column"}
        justifyContent={"space-between"}
        px={isSmallScreen ? 1 : 4}
        pt={2}
        bgcolor={"#ededed"}
      >
        <Stack flex={1} borderBottom={"2px dashed lightgray"}>
          <Typography variant="h7" fontSize="20px" fontWeight={"bold"}>
            {name}
          </Typography>
          <Typography variant="h6" color="orangered" fontSize="16px">
            - Rs {Math.floor(discountAmount)}
          </Typography>
          <Typography variant="h7" fontSize="12px" mb={1}>
            {discountValue}% off Applied - Enjoy Your Exclusive Savings!
          </Typography>
        </Stack>
        <Stack
          flex={1}
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Box
            color="green"
            fontSize={isSmallScreen ? "10px" : "15px"}
            fontWeight={"bold"}
            textAlign={"start"}
            display={"flex"}
            alignItems={"center"}
            gap={isSmallScreen ? 0 : 1}
          >
            <CheckCircle />
            Voucher Applied
          </Box>
          <Button variant="text" color="error" onClick={onRemove}>
            Remove
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default VoucherCard;
