import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  IconButton,
  Input,
  InputAdornment,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import referImg from "../../../assets/invite_cylinder.png";
import giftImg from "../../../assets/invite_gift.png";
import { CopyAllOutlined, Share } from "@mui/icons-material";
import { toast } from "react-toastify";
import {
  getReferalLink,
  getReferreeVoucherDetails,
  getReferrerVoucherDetails,
} from "../../../redux/store/action/unauth-action/discounted_voucher-action/discounted_voucher-action";
import { useDispatch, useSelector } from "react-redux";

const InviteFriends = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [inviteLink, setInviteLink] = useState(null);
  const [inviteActive, setInviteActive] = useState(false);
  const dispatch = useDispatch();
  const { authuserdetail } = useSelector(({ authStates }) => authStates);
  const { referalLink, referrerVoucherData, referreeVoucherData } = useSelector(
    ({ discountedVoucherStates }) => discountedVoucherStates
  );
  const { selectaddress } = useSelector(({ addressStates }) => addressStates);
  const { currentprices } = useSelector(({ orderStates }) => orderStates);
  const FilterObject = currentprices.find(
    (price) => price.city === selectaddress.city
  );
  const [referrerDiscount, setReferrerDiscount] = useState(0);
  const [referreeDiscount, setReferreeDiscount] = useState(0);
  // console.log(
  //   "referrerVoucherData>>>>>>>>>>>> ",
  //   referrerVoucherData,
  //   referreeVoucherData,
  //   referrerDiscount
  // );

  const resHandler = (data) => {
    if (data) {
      setInviteLink(referalLink.referralLink);
    }
  };

  const referrerResHandler = (data) => {
    if (data) {
      const percentage = data?.data?.data[0]?.discountValue;
      const singleLPGPrice = FilterObject?.price;
      const discount = (percentage * singleLPGPrice) / 100;
      setReferrerDiscount(discount);
    }
  };

  const referreeResHandler = (data) => {
    if (data) {
      if (data) {
        const percentage = data?.data?.data[0]?.discountValue;
        const singleLPGPrice = FilterObject?.price;
        const discount = (percentage * singleLPGPrice) / 100;
        setReferreeDiscount(discount);
      }
    }
  };

  useEffect(() => {
    dispatch(getReferalLink(authuserdetail?.customer?.customerId, resHandler));
    dispatch(getReferrerVoucherDetails("Referrer", referrerResHandler));
    dispatch(getReferreeVoucherDetails("Referee", referreeResHandler));
  }, [dispatch]);

  const handleInviteLink = () => {
    dispatch(getReferalLink(authuserdetail?.customer?.customerId, resHandler));
    setInviteActive(true);
  };

  const handleInviteLinkClose = () => {
    setInviteActive(false);
  };

  // const handleCopy = () => {
  //   navigator.clipboard.writeText(inviteLink);
  //   toast.success("Invite Code copied!");
  // };

  const handleCopy = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(inviteLink)
        .then(() => {
          toast.success("Invite Code copied!");
        })
        .catch((error) => {
          console.error("Clipboard copy failed:", error);
          fallbackCopy(inviteLink);
        });
    } else {
      fallbackCopy(inviteLink);
    }
  };

  const fallbackCopy = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    toast.success("Invite Code copied");
  };

  return (
    <>
      <Stack rowGap={5}>
        <Card
          sx={{
            padding: isSmallScreen
              ? 1
              : isMediumScreen
              ? 2
              : isLargeScreen
              ? 3
              : 5,
            borderRadius: 4,
            boxShadow: 4,
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img src={referImg} width={"75%"} />
              </Box>
              <Box position={"relative"}>
                <Box
                  textAlign={"center"}
                  position={"absolute"}
                  left={0}
                  right={0}
                  top={-50}
                  boxShadow={10}
                  borderRadius={10}
                  sx={{ backgroundColor: "white" }}
                >
                  <Box
                    py={isSmallScreen ? 2 : 3}
                    mb={isSmallScreen ? 2 : 5}
                    borderBottom={1}
                    borderColor={"#757575"}
                  >
                    <h2
                      style={{ fontSize: isSmallScreen ? "20px" : "inherit" }}
                    >
                      Exclusive Rewards!
                    </h2>
                  </Box>
                  <Box
                    textAlign={"start"}
                    px={isSmallScreen ? 1 : 5}
                    mb={isSmallScreen ? 1 : 5}
                    sx={{
                      display: "flex",
                      // alignItems: "center",
                    }}
                  >
                    <Box mr={isSmallScreen ? 1 : 4}>
                      <img src={giftImg} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <h6 style={{ color: "black" }}>
                        Invite a friend and get a Rs.{" "}
                        {Math.floor(referrerDiscount)} voucher when they make
                        their first purchase!
                      </h6>
                      <h6 color="#4978b9" style={{ color: "#4978b9" }}>
                        Your friend also receives a Rs.{" "}
                        {Math.floor(referreeDiscount)} voucher on placing their
                        first gas cylinder order.
                      </h6>
                      <h6 color="#757575" style={{ color: "#757575" }}>
                        Start inviting now and enjoy the rewards!
                      </h6>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            {inviteActive && (
              <Box mt={40}>
                {/* <Input
                  disabled
                  id="outlined-disabled"
                  // label="Disabled"
                  value={inviteLink}
                />
                <IconButton onClick={handleCopy}>
                  <Share />
                </IconButton> */}
                <TextField
                  id="outlined-basic"
                  fullWidth
                  variant="outlined"
                  value={"Referral Code : " + inviteLink}
                  sx={{
                    "& .MuiInputBase-input": {
                      WebkitTextFillColor: "rgba(0 , 0 , 0 , 0.7)",
                      minWidth: "278px",
                      color: "black",
                      cursor: "auto",
                      width: "100%",
                      height: "15px",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#0A74B9",
                        // borderStyle: "dashed",
                      },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#0A74B9",
                      },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#0A74B9",
                        backgroundColor: "#0A74B9",
                        backgroundColor: "rgba(10, 116, 185, 0.2)",
                        borderWidth: 2,
                        width: "100%",
                      },
                    "& .MuiInputLabel-root": {
                      color: "rgba(0, 0, 0, 0.8)",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#0A74B9",
                    },
                  }}
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       <IconButton onClick={handleCopy}>
                  //         <CopyAllOutlined />
                  //       </IconButton>
                  //     </InputAdornment>
                  //   ),
                  //   readOnly: true,
                  // }}
                />
              </Box>
            )}
            {!inviteActive && (
              <Box mt={inviteActive ? 5 : 40}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleInviteLink}
                >
                  Share Your Link
                </Button>
              </Box>
            )}
            {inviteActive && (
              <Box
                mt={inviteActive ? 2 : 40}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ textTransform: "capitalize" }}
                >
                  Copy{" "}
                  <IconButton
                    onClick={handleCopy}
                    style={{ color: "white" }}
                    size="small"
                  >
                    <CopyAllOutlined />
                  </IconButton>
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="large"
                  onClick={handleInviteLinkClose}
                  style={{ textTransform: "capitalize" }}
                >
                  Cancel
                </Button>
              </Box>
            )}
          </Box>
        </Card>
      </Stack>
    </>
  );
};

export default InviteFriends;
