import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Stack,
  Typography,
  TextField,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import React from "react";
import DiscountedVoucherModal from "../../Modal/DiscountedVoucherModal";
import VoucherCard from "../VoucherCard";
import voucherImg from "../../assets/voucherIcon.png";

const NextOrderConfirmationModal = ({
  Confirmationopen,
  ConfirmationopenhandleClose,
  itemlist,
  RowData,
  orderPricingDetails,
  isSmallScreen,
  walletList,
  selectedVoucher,
  handleVoucherRemove,
  selectaddress,
  filteredPaymentMethods,
  handlePaymentMethodChange,
  ShowCardNumber,
  Confirmationopenopenhandle,
  currentDate,
  FilterObject,
  isUrgent,
  discountedVouchersList,
  handleVoucherOpen,
  handleVoucherClose,
  voucherOpen,
  handleDeliveryType,
  Urgent_Delivery_Charges,
  handleVoucherSelected,
}) => {
  return (
    <Modal
      open={Confirmationopen}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      // sx={{ maxHeight: "90vh" , overflow: "auto"}}
    >
      <div className="main_modal_div">
        <Typography
          id="modal-title"
          variant="h6"
          sx={{
            textAlign: "center",
            color: "#1591c7",
            fontWeight: "bold",
            fontSize: "30px",
            marginBottom: "10px",
          }}
        >
          Order Confirmation
        </Typography>
        <IconButton
          aria-label="close"
          onClick={ConfirmationopenhandleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
            backgroundColor: "#dc0000",
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box mt={1} mb={0}>
          <Stack direction="row" justifyContent="space-between" p={1}>
            <Typography sx={{ color: "#818181" }}>Item</Typography>
            <Typography sx={{ color: "#1591c7" }}>
              {itemlist[0]?.name}
            </Typography>
          </Stack>
          {/* <Divider variant="middle" flexItem /> */}
          <Stack direction="row" justifyContent="space-between" p={1}>
            <Typography sx={{ color: "#818181" }}>Quantity</Typography>
            <Typography sx={{ color: "#1591c7" }}>{RowData?.qty}</Typography>
          </Stack>
          {/* <Divider variant="middle" flexItem /> */}
          <Stack direction="row" justifyContent="space-between" p={1}>
            <Typography sx={{ color: "#818181" }}>
              Current Delivery Date
            </Typography>
            <Typography sx={{ color: "#1591c7" }}>
              {dayjs(currentDate).format("DD-MM-YYYY")}
            </Typography>
          </Stack>
          {/* <Divider variant="middle" flexItem /> */}
          <Stack direction="row" justifyContent="space-between" p={1}>
            <Typography sx={{ color: "#818181" }}>
              No.of Days for Refill
            </Typography>
            {/* <Typography sx={{ color: "#1591c7" }}>
                {daysDifference} Days
              </Typography> */}
            <Typography sx={{ color: "#1591c7" }}>
              {RowData?.numOfDay} Days
            </Typography>
          </Stack>
          {discountedVouchersList?.length > 0 && selectedVoucher && (
            <>
              {/* <Divider variant="middle" flexItem /> */}
              <Stack direction="row" justifyContent="space-between" p={1}>
                <Box position={"relative"}>
                  <Box position={"absolute"} left={"110%"} top={-10}>
                    <Typography
                      border={1}
                      sx={{ color: "#818181", borderStyle: "dashed" }}
                      px={2}
                      py={0}
                      borderRadius={10}
                      variant="body2"
                    >
                      Voucher
                    </Typography>
                  </Box>
                  <Typography sx={{ color: "#818181" }}>
                    {selectedVoucher?.name}
                  </Typography>
                </Box>
                {/* walletList?.wallet?.balance */}
                {!selectedVoucher ? (
                  <Typography sx={{ color: "#1591c7" }}>Rs 0</Typography>
                ) : (
                  <Typography sx={{ color: "orangered" }}>
                    - Rs{" "}
                    {Math.floor(orderPricingDetails?.discountAmount)
                      .toFixed(0)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography>
                )}
              </Stack>
            </>
          )}
          {discountedVouchersList?.length > 0 && walletList?.wallet?.balance < orderPricingDetails?.total  && (
            <>
              {/* <Divider variant="middle" flexItem /> */}

              <Stack direction="column" justifyContent="flex-end" p={1}>
                {!selectedVoucher && (
                  <Box
                    sx={{
                      fontSize: { xs: "13px", sm: "15px" },
                      fontWeight: "bold",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={handleVoucherOpen}
                  >
                    <img src={voucherImg} width={isSmallScreen ? 30 : 40} />
                    Apply a Voucher
                  </Box>
                )}
                {voucherOpen && (
                  <DiscountedVoucherModal
                    open={voucherOpen}
                    handleClose={handleVoucherClose}
                    handleVoucherSelected={handleVoucherSelected}
                    Total_Bill={orderPricingDetails?.amount}
                    selectedVoucherId={selectedVoucher?.id}
                  />
                )}
                {selectedVoucher ? (
                  <VoucherCard
                    discountValue={selectedVoucher?.discountValue}
                    name={selectedVoucher?.name}
                    discountAmount={orderPricingDetails?.discountAmount}
                    isSmallScreen={isSmallScreen}
                    onRemove={handleVoucherRemove}
                  />
                ) : null}
              </Stack>
            </>
          )}

          {/* <Divider variant="middle" flexItem /> */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
            p={1}
          >
            <Typography
              sx={{
                color: "#818181",
                display: "flex",
                alignItems: isSmallScreen ? "start" : "center",
                flexDirection: isSmallScreen ? "column" : "row",
              }}
            >
              Delivery Charges{" "}
            </Typography>
            {console.log("RowData::::::::::::::::: ", RowData)}
            {/* walletList?.wallet?.balance */}
            <Typography sx={{ color: "#1591c7" }}>
              Rs {orderPricingDetails?.deliveryCharges}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
            p={1}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems={"center"}
              // p={1}
            >
              <Typography
                sx={{
                  color: "#818181",
                  display: "flex",
                  alignItems: isSmallScreen ? "start" : "center",
                  flexDirection: isSmallScreen ? "column" : "row",
                }}
              >
                <label
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    alignItems: "center",
                    margin: 0,
                    border: isSmallScreen ? "" : "2px solid gray",
                    padding: isSmallScreen ? "2px" : "5px 15px",
                    borderRadius: isSmallScreen ? "5px" : "20px",
                    boxShadow: isSmallScreen ? "" : "1px 1px 5px gray",
                    fontSize: isSmallScreen ? "10px" : "12px",
                    minWidth: "100px",
                  }}
                >
                  <Checkbox
                    name="deliveryType"
                    value="Urgent"
                    onChange={() => handleDeliveryType(Urgent_Delivery_Charges)}
                    inputProps={{ "aria-label": "Urgent Delivery" }}
                    size="small" // This makes the checkbox smaller
                    sx={{ padding: 0, marginRight: 1 }}
                  />
                  {isSmallScreen
                    ? `Urgent Delivery Rs ${FilterObject?.urgentDeliveryCharges}`
                    : `Urgent Delivery Charges Rs ${FilterObject?.urgentDeliveryCharges}`}
                </label>
              </Typography>
              {/* walletList?.wallet?.balance */}
              {/* <Typography sx={{ color: "#1591c7" }}>
              Rs {isUrgent ? orderPricingDetails?.urgentDeliveryCharges : 0}
            </Typography> */}
            </Stack>
            {/* walletList?.wallet?.balance */}
            <Typography sx={{ color: "#1591c7" }}>
              Rs {orderPricingDetails?.urgentDeliveryCharges}
            </Typography>
          </Stack>
          {/* <Divider variant="middle" flexItem /> */}
          <Stack direction="row" justifyContent="space-between" p={1}>
            <Typography sx={{ color: "#818181" }}>Total Bill Amount</Typography>
            {/* walletList?.wallet?.balance */}
            {/* <Typography sx={{ color: "#1591c7" }}>
                Rs{" "}
                {RowData?.total !== null
                  ? (RowData?.total - RowData?.securityDeposit)
                      .toFixed(0)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0"}
              </Typography> */}
            {/* <Typography sx={{ color: "#1591c7" }}>
                Rs{" "}
                {FilterObject !== null
                  ? Math.floor(
                      FilterObject?.price * RowData?.qty +
                        Urgent_Delivery_Charges -
                        VoucherDiscount
                    )
                      ?.toFixed(0)
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0"}
              </Typography> */}
            <Typography sx={{ color: "#1591c7" }}>
              Rs{" "}
              {Math.floor(orderPricingDetails?.total)
                ?.toFixed(0)
                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Typography>
          </Stack>

          {walletList?.wallet?.balance > 0 ? (
            <>
              <Divider variant="fullWidth" flexItem />
              <Stack direction="row" justifyContent="space-between" p={1}>
                <Typography sx={{ color: "#818181" }}>Wallet</Typography>
                <Typography sx={{ color: "#1591c7" }}>
                  - Rs.{" "}
                  {walletList?.wallet?.balance
                    ?.toFixed(0)
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>
              </Stack>
            </>
          ) : null}
          <Divider variant="fullWidth" flexItem />
          <Stack direction="row" justifyContent="space-between" p={1}>
            <Typography fontWeight={"bold"} sx={{ color: "#818181" }}>
              Net Payable
            </Typography>
            {/* <Typography sx={{ color: "#1591c7" }}>
                    Rs.{" "}
                    {Math.max(
                      RowData?.total -
                        RowData?.securityDeposit -
                        walletList?.wallet?.balance,
                      0
                    )
                      .toFixed(0)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography> */}
            {/* <Typography sx={{ color: "#1591c7" }}>
                    Rs.{" "}
                    {FilterObject !== null
                      ? Math.floor(
                          FilterObject?.price * RowData?.qty +
                            Urgent_Delivery_Charges -
                            VoucherDiscount -
                            walletList?.wallet?.balance >
                            0
                            ? FilterObject?.price * RowData?.qty +
                                Urgent_Delivery_Charges -
                                VoucherDiscount -
                                walletList?.wallet?.balance
                            : 0
                        )
                          ?.toFixed(0)
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "0"}
                  </Typography> */}
            <Typography fontWeight={"bold"} sx={{ color: "#1591c7" }}>
              Rs.{" "}
              {Math.floor(
                orderPricingDetails?.total -
                Math.floor(orderPricingDetails?.discountAmount) -
                  walletList?.wallet?.balance >
                  0
                  ? orderPricingDetails?.total -
                  Math.floor(orderPricingDetails?.discountAmount) -
                      walletList?.wallet?.balance
                  : 0
              )
                ?.toFixed(0)
                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" p={1}>
            <Typography sx={{ color: "#818181" }}>
              Remaining Wallet Balance
            </Typography>
            <Typography sx={{ color: "#1591c7" }}>
              Rs.{" "}
              {Math.floor(
                orderPricingDetails?.total -
                Math.floor(orderPricingDetails?.discountAmount) -
                  walletList?.wallet?.balance >
                  0
                  ? 0
                  : walletList?.wallet?.balance -
                      orderPricingDetails?.total -
                      Math.floor(orderPricingDetails?.discountAmount)
              )
                ?.toFixed(0)
                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Typography>
          </Stack>
          {/* <Divider variant="middle" flexItem /> */}
          <Stack p={1}>
            <Typography sx={{ color: "#818181", fontSize: "15px" }}>
              Delivery Address
            </Typography>
            <Typography sx={{ color: "#1591c7" }}>
              {selectaddress?.address}
            </Typography>
          </Stack>
          <Stack p={1}>
            <Typography sx={{}}>Payment</Typography>
            <Typography sx={{ color: "#1591c7", fontSize: "15px" }}>
              {/* {RowData?.paymentMethod} */}
            </Typography>
          </Stack>
        </Box>
        <div className="view_order_detail_inner_body_payment_section_div">
          {filteredPaymentMethods.map((payment, index) => (
            <div key={index}>
              <label htmlFor={payment.id}>
                <input
                  type="radio"
                  name="Payment"
                  id={payment.id}
                  value={payment.id}
                  // checked={paymentMethod === payment.paymentMethod}
                  onChange={handlePaymentMethodChange}
                />
                {payment.paymentMethod}
              </label>
            </div>
          ))}
        </div>
        {/* {ShowCardNumber ? (
          <div>
            <br />
            <h5>Card No</h5>
            <TextField
              label="Card Number"
              variant="outlined"
              // InputLabelProps={{
              //     shrink: true,
              // }}
              style={{ width: "100%" }}
              value={CardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
            />
            <br />
          </div>
        ) : (
          <></>
        )} */}
        <Typography
          sx={{
            color: "#1591c7",
            fontSize: "17px",
            fontWeight: "bold",
            textAlign: "center",
            paddingBottom: "10px",
          }}
        >
          Do you want to place this order?
        </Typography>
        <Stack direction="row" justifyContent="center" spacing={3} p={0}>
          <Button
            variant="contained"
            sx={{
              width: "200px",
              height: "50px",
              borderRadius: "20px",
              backgroundColor: "green",
              "&:hover": {
                backgroundColor: "darkgreen",
              },
            }}
            onClick={() => Confirmationopenopenhandle()}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "200px",
              height: "50px",
              borderRadius: "20px",
              backgroundColor: "red",
              "&:hover": {
                backgroundColor: "darkred",
              },
            }}
            onClick={ConfirmationopenhandleClose}
          >
            No
          </Button>
        </Stack>
      </div>
    </Modal>
  );
};

export default NextOrderConfirmationModal;
