/***** Note: Axios Configuration File *****/
import axios from "axios";

const urls = {
    localHostUrl: process.env.NEXT_PUBLIC_LOCAL_HOST_URL,
    // hostedUrl: "http://connect.uzairbaig.com:5107/api"
    hostedUrl: "https://connect.uzairbaig.com:51003/api"
    // hostedUrl: "http://connect.uzairbaig.com:5108/api"
    // hostedUrl: "http://connect.uzairbaig.com:9080/api"
    // hostedUrl: "http://192.168.81.108:51003/api"
};


// Default config options
const defaultOptions = {
    baseURL: urls.hostedUrl,
    headers: {
        "Content-Type": "application/json",
        "DeviceId": typeof window !== 'undefined' ? window.navigator.userAgent : "server",
    }
};

// Create axios instance
const instance = axios.create(defaultOptions);

// Function to refresh token
const refreshToken = async () => {
    const fetchToken = localStorage.getItem("AuthToken");
    const refreshToken = localStorage.getItem("RefreshToken");
    const response = await instance.get(`/Auth/RefreshToken?token=${fetchToken}&refreshToken=${refreshToken}`);
    const newToken = response.data?.data?.jwToken;
    localStorage.setItem("AuthToken", newToken);
    return newToken;
};


// Set the AUTH token for any request
instance.interceptors.request.use((config) => {
    const fetchToken = localStorage.getItem("AuthToken");
    config.headers.Authorization = fetchToken ? `Bearer ${fetchToken}` : "";
    return config;
});

// Intercept the response to handle token expiration
instance.interceptors.response.use(
    (response) => response,
    async (error) => {
        console.error("Request error:", error);
        const originalRequest = error.config;

        // Handle 403 Unauthorized response
        if (error.response?.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;
            // Clear cookies
            document.cookie.split(";").forEach((c) => {
                document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            });
            // Clear local storage
            localStorage.clear();
            window.location.href = '/'
            // Optionally, redirect to login page or any other action
            return Promise.reject(error);
        } else if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const newToken = await refreshToken();
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            return instance(originalRequest);
        }
        return Promise.reject(error);
    }
);

export default instance;