import React, { useEffect, useState } from "react";
import moment from "moment";
import cylinder_img from "../../../../assets/Group 11613@3x.png";
import { FaMinus, FaPlus } from "react-icons/fa";
import {
  Card,
  CardContent,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Box,
  IconButton,
  Modal,
  TextField,
  useMediaQuery,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  CreateOrder,
  DigiCashOTPVerify,
  DigiCashRegenerateOTP,
  GetCustomerOrder,
  GetCustomerScheduledOrder,
  GetOrderPricingDetails,
} from "../../../../redux/store/action/unauth-action/order-action/order-action";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { MuiOtpInput } from "mui-one-time-password-input";
import checkedcylinder from "../../../../../src/assets/checkedcylinder.png";
import Loader from "../../../../Loader/Loader";
import DiscountedVoucherModal from "../../../../Modal/DiscountedVoucherModal";
import dayjs from "dayjs";
import { getVouchersByCustomerId } from "../../../../redux/store/action/unauth-action/discounted_voucher-action/discounted_voucher-action";
import OrderConfirmationModal from "../../../../components/Modals/OrderConfirmationModal";
import ThankYouModal from "../../../../components/Modals/ThankYouModal";
import voucherImg from "../../../../assets/voucherIcon.png";
import VoucherCard from "../../../../components/VoucherCard";

function Refill() {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState(null);

  const { authuserdetail } = useSelector(({ authStates }) => authStates);
  const { selectaddress } = useSelector(({ addressStates }) => addressStates);
  const {
    paymentmethodslist,
    currentprices,
    createorderdetail,
    itemlist,
    scheduledorderList,
    orderPricingDetails,
  } = useSelector(({ orderStates }) => orderStates);
  const { AllCylinderStatusList } = useSelector(
    ({ dashboardStates }) => dashboardStates
  );
  const { walletList } = useSelector(({ walletStates }) => walletStates);
  const { discountedVouchersList } = useSelector(
    ({ discountedVoucherStates }) => discountedVoucherStates
  );

  const inactiveOrders = scheduledorderList?.filter(
    (order) => !order?.isScheduleActive
  );

  const [quantity, setQuantity] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [CurrectCityObject, setCurrectCityObject] = useState(null);
  // Pricing State Start Here
  const [Delivery_Date, setDelivery_Date] = useState(null);
  const [Recurring_Order, setRecurring_Order] = useState(null);
  const [LPG_Price, setLPG_Price] = useState(null);
  const [GST, setGST] = useState(null);
  const [Consumer_Price, setConsumer_Price] = useState(null);
  const [Discount, setDiscount] = useState(null);
  const [VoucherdDetails, setVoucherDetails] = useState(null);
  const [VoucherDiscount, setVoucherDiscount] = useState(null);
  const [Price_after_discount, setPrice_after_discount] = useState(null);
  const [Price_after_Voucherdiscount, setPrice_after_Voucherdiscount] =
    useState(null);
  const [Delivery_Charges, setDelivery_Charges] = useState(null);
  const [Security_Deposit, setSecurity_Deposit] = useState(null);
  const [Total_Bill, setTotal_Bill] = useState(null);
  const [CardNumber, setCardNumber] = useState(null);
  const [ShowCardNumber, setShowCardNumber] = useState(false);
  // Pricing State End Here
  // Modals Function Start Here
  const [open, setOpen] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [digicashOtpModal, setDigicashOtpModal] = useState(false);
  const [digicashresdata, setdigicashresdata] = useState(null);
  const [digicashOTP, setdigicashOTP] = useState("259168");
  // Modals Function End Here
  //? Urgent Delivery Functionality Start Here
  const [Urgent_Delivery_Charges, setUrgent_Delivery_Charges] = useState(0);
  const [Urgent_Delivery, setUrgent_Delivery] = useState(null);
  const [isUrgent, setIsUrgent] = useState(false);
  //? Urgent Delivery Functionalty End Here

  //! Delivery Type Logic Start
  const handleDeliveryType = (charges) => {
    if (isUrgent === false) {
      setIsUrgent(true);
      console.log("Total_Bill in handleDeliveryType:::::: ", Total_Bill);
      setUrgent_Delivery_Charges(charges);
      setTotal_Bill(Total_Bill + charges * quantity);
      // setDelivery_Charges(Delivery_Charges + charges);
    } else {
      setIsUrgent(false);
      setUrgent_Delivery_Charges(0);
      setTotal_Bill(Total_Bill - charges * quantity);
      // setDelivery_Charges(Delivery_Charges - charges);
    }
  };
  //! Delivery Type Logic End

  //? Discounted Voucher Functionality Start Here
  const [voucherOpen, setVoucherOpen] = useState(false);
  const handleVoucherOpen = () => setVoucherOpen(true);
  const handleVoucherClose = () => setVoucherOpen(false);
  //? Discounted Voucher Functionality Ends Here

  //? SelectVoucher Logic Starts Here
  const handleVoucherSelected = (data) => {
    if (quantity > 0) {
      // if (walletList?.wallet?.balance > orderPricingDetails?.total) {
      //   return;
      // }
      // Save the current total bill to avoid issues with stale state
      let currentBill = Total_Bill;

      if (selectedVoucher) {
        // Reset to original price if a voucher is already selected
        setTotal_Bill(LPG_Price); // Reset to original price
        currentBill = LPG_Price; // Keep track of reset price for further calculation
      }

      setSelectedVoucher(data);
      let discount = (data?.discountValue / 100) * LPG_Price;
      discount = Number(Math.ceil(discount).toFixed(0));

      if (discount > data.discountCapAmount) {
        setVoucherDiscount(data.discountCapAmount);
        setTotal_Bill(currentBill - data.discountCapAmount);
      } else {
        setVoucherDiscount(discount);
        setTotal_Bill(currentBill - discount);
      }
      toast.success("Voucher Selected!");
    }

    handleVoucherClose();
  };
  //? SelectVoucher Logic Ends Here

  //? RemoveVoucher Logic Starts Here
  const handleVoucherRemove = (data) => {
    handleVoucherClose();
    setSelectedVoucher(null);
    setVoucherDiscount(0);
    setTotal_Bill(LPG_Price);
    toast.error("Voucher Removed!");
  };
  //? RemoveVoucher Logic Ends Here

  const [OrderConfirmationdetails, setOrderConfirmationdetails] =
    useState(null);

  const unsubscribeCylinderList = scheduledorderList.filter(
    (item) => item.isScheduleActive == false
  );
  const totalUnsubscribedCylinders = unsubscribeCylinderList
    .filter((item) => item.totalCylindersInHand != 0)
    .map((item) => item.totalCylindersInHand);

  const handleOpen = () => {
    const today = new Date();
    const twoDaysLater = new Date(today.setDate(today.getDate()));
    const yyyy = twoDaysLater.getFullYear();
    const mm = String(twoDaysLater.getMonth() + 1).padStart(2, "0");
    const dd = String(twoDaysLater.getDate()).padStart(2, "0");
    const finalDate = `${yyyy}-${mm}-${dd}`;

    let refillvalue = AllCylinderStatusList?.length;

    if (refillvalue === 0) {
      toast.error("No Cylinder In Hand");
    } else {
      if (!Delivery_Date) {
        toast.error("Please enter a Delivery Date!");
      } else if (Delivery_Date < finalDate) {
        toast.error("Please enter a Correct Delivery Date!");
      } else if (Number(Recurring_Order) === 0) {
        toast.error("Recurring Order must be greater than 0");
      } else if (Number(Recurring_Order) <= 0) {
        toast.error("Recurring Order must be greater than 0");
      } else if (Number(Recurring_Order) > 23) {
        toast.error("Number of days cannot exceed 23");
      } else if (!Recurring_Order.trim()) {
        toast.error("Please enter a Recurring Order!");
      } else if (quantity === 0) {
        toast.error("Please Add Cylinder!");
      } else if (!paymentMethod) {
        toast.error("Please Select a Payment Method!");
      } else if (paymentMethod === 2 && CardNumber?.length <= 15) {
        toast.error("Invalid Card Number!");
      } else {
        setOpen(true);

        let data = {
          deliverydate: Delivery_Date,
          recurringorder: Recurring_Order,
          itemname: itemlist[0]?.name,
          quantity: quantity,
          totalbill: Total_Bill,
          address: selectaddress.address,
          paymentmethod: paymentMethod,
        };

        setOrderConfirmationdetails(data);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const plusHandlerAndMinus = (value) => {
    const newQuantity = quantity + value;
    // let refillvalue = AllCylinderStatusList?.length
    let refillvalue =
      totalUnsubscribedCylinders?.length === 0
        ? 0
        : totalUnsubscribedCylinders?.reduce((a, b) => a + b, 0); // Corrected condition

    if (refillvalue === 0) {
      toast.error("No Cylinder In Hand");
    } else if (newQuantity < 0) {
      toast.error("Quantity cannot be negative");
    } else if (newQuantity > refillvalue) {
      toast.info(`You can only refill ${refillvalue} cylinders.`);
    } else {
      setQuantity(newQuantity);
    }
  };

  const handlePaymentMethodChange = (event) => {
    console.log(event.target.value);
    if (event.target.value == 2) {
      setPaymentMethod(event.target.value);
      setShowCardNumber(true);
    } else {
      setPaymentMethod(event.target.value);
      setShowCardNumber(false);
    }
  };

  // let currentBill = LPG_Price;
  // useEffect(() => {
  //   if (currentprices && selectaddress) {
  //     const FilterObject = currentprices.find(
  //       (price) => price.city === selectaddress.city
  //     );
  //     if (FilterObject) {
  //       setCurrectCityObject(FilterObject);
  //       dispatch(
  //         GetOrderPricingDetails({
  //           FilterObject,
  //           authuserdetail,
  //           quantity,
  //           isUrgent,
  //           invoiceType: "EmptyReturn",
  //         })
  //       );
  //       // Update other state variables based on FilterObject
  //       setLPG_Price(
  //         Math.floor(
  //           FilterObject?.price * quantity +
  //             ((FilterObject?.price * quantity) / 100) * FilterObject?.gstTax
  //         )
  //       );
  //       // setLPG_Price(Math.floor(FilterObject?.price * quantity + FilterObject?.price * quantity / 100 * FilterObject?.gstTax));
  //       setGST(((FilterObject?.price * quantity) / 100) * FilterObject?.gstTax);
  //       setConsumer_Price(
  //         ((FilterObject?.price * quantity) / 100) * FilterObject?.gstTax +
  //           FilterObject?.price * quantity
  //       );
  //       setDiscount(
  //         ((FilterObject?.price * quantity) / 100) * FilterObject?.discount
  //       );
  //       setPrice_after_discount(
  //         ((FilterObject?.price * quantity) / 100) * FilterObject?.gstTax +
  //           FilterObject?.price * quantity -
  //           ((FilterObject?.price * quantity) / 100) * FilterObject?.discount
  //       );
  //       setDelivery_Charges(FilterObject?.deliveryCharges * quantity);
  //       setSecurity_Deposit(0);
  //       setUrgent_Delivery(FilterObject?.urgentDeliveryCharges);
  //       // setTotal_Bill(
  //       //   ((FilterObject?.price * quantity) / 100) * FilterObject?.gstTax +
  //       //     FilterObject?.price * quantity -
  //       //     ((FilterObject?.price * quantity) / 100) * FilterObject?.discount +
  //       //     FilterObject?.deliveryCharges * quantity
  //       // );
  //       setTotal_Bill(
  //         Math.floor(
  //           ((FilterObject?.price * quantity) / 100) * FilterObject?.gstTax +
  //             FilterObject?.price * quantity -
  //             ((FilterObject?.price * quantity) / 100) *
  //               FilterObject?.discount +
  //             FilterObject?.deliveryCharges * quantity -
  //             VoucherDiscount
  //         )
  //       );
  //       if (quantity === 0) {
  //         setVoucherDiscount(0);
  //         setTotal_Bill(0);
  //         setSelectedVoucher(null);
  //         setUrgent_Delivery_Charges(0);
  //         setIsUrgent(false);
  //       }
  //       if (quantity >= 1 && isUrgent) {
  //         setTotal_Bill(
  //           ((FilterObject?.price * quantity) / 100) * FilterObject?.gstTax +
  //             FilterObject?.price * quantity -
  //             ((FilterObject?.price * quantity) / 100) *
  //               FilterObject?.discount +
  //             FilterObject?.deliveryCharges * quantity +
  //             FilterObject?.urgentDeliveryCharges * quantity
  //         );
  //         console.log("Total_Bill Inner:::::::::::::: ", Total_Bill);
  //       }
  //       if (quantity >= 1 && selectedVoucher) {
  //         if (selectedVoucher) {
  //           setTotal_Bill(LPG_Price);
  //           currentBill = LPG_Price;
  //         }
  //         let discount = (selectedVoucher?.discountValue / 100) * LPG_Price;
  //         if (discount > selectedVoucher?.discountCapAmount) {
  //           setVoucherDiscount(selectedVoucher?.discountCapAmount);
  //           setTotal_Bill(currentBill - selectedVoucher?.discountCapAmount);
  //         } else {
  //           setVoucherDiscount(discount);
  //           setTotal_Bill(currentBill - discount);
  //         }
  //       }
  //       if (quantity >= 1 && selectedVoucher && isUrgent) {
  //         if (selectedVoucher) {
  //           setTotal_Bill(LPG_Price);
  //           currentBill = LPG_Price;
  //         }
  //         let discount = (selectedVoucher?.discountValue / 100) * LPG_Price;
  //         if (discount > selectedVoucher?.discountCapAmount) {
  //           setVoucherDiscount(selectedVoucher?.discountCapAmount);
  //           setTotal_Bill(
  //             currentBill -
  //               selectedVoucher?.discountCapAmount +
  //               FilterObject?.urgentDeliveryCharges * quantity
  //           );
  //         } else {
  //           setVoucherDiscount(discount);
  //           setTotal_Bill(
  //             currentBill -
  //               discount -
  //               FilterObject?.urgentDeliveryCharges * quantity
  //           );
  //         }
  //       }
  //       if (
  //         FilterObject?.price * quantity <
  //         selectedVoucher?.minimumOrderAmount
  //       ) {
  //         setVoucherDiscount(0);
  //         setSelectedVoucher(null);
  //         toast.error("Voucher Removed!");
  //       }
  //     }
  //   }
  //   // }, [currentprices, selectaddress, quantity, currentBill]);
  // }, [currentprices, selectaddress, quantity, isUrgent]);

  useEffect(() => {
    if (currentprices && selectaddress) {
      const FilterObject = currentprices.find(
        (price) => price.city === selectaddress.city
      );
      dispatch(
        GetOrderPricingDetails({
          FilterObject,
          authuserdetail,
          quantity,
          isUrgent,
          invoiceType: "EmptyReturn",
          voucherId: selectedVoucher?.id,
        })
      );
      if (FilterObject) {
        setCurrectCityObject(FilterObject);
        setUrgent_Delivery(FilterObject?.urgentDeliveryCharges);
        if (quantity === 0) {
          setVoucherDiscount(0);
          setTotal_Bill(0);
          setSelectedVoucher(null);
          setUrgent_Delivery_Charges(0);
          setIsUrgent(false);
        }
        if (
          FilterObject?.price * quantity <
          selectedVoucher?.minimumOrderAmount
        ) {
          setVoucherDiscount(0);
          setSelectedVoucher(null);
          toast.error("Voucher Removed!");
        }
      }
    }
  }, [
    currentprices,
    selectaddress,
    quantity,
    isUrgent,
    selectedVoucher,
    // orderPricingDetails,
  ]);

  console.log("orderPricingDetails:::::::: ", orderPricingDetails);

  const resHandler = (data) => {
    console.log("Error in CreateOrder:", data?.data?.data);
    if (data?.data?.statusCode === 200) {
      setdigicashresdata(data?.data?.data);
      if (ShowCardNumber == true) {
        setDigicashOtpModal(true);
        handleClose();
        setIsloading(false);
      } else {
        setConfirmationModal(true);
      }
    } else {
      setIsloading(false);
      setOpen(false);
    }
    setIsloading(false);
  };

  const CreateNewOrder = () => {
    let paidAmount;
    if (orderPricingDetails?.total > walletList?.wallet?.balance) {
      // paidAmount = Math.floor(walletList?.wallet?.balance);
      paidAmount = walletList?.wallet?.balance;
    } else {
      // paidAmount = Math.floor(Total_Bill);
      paidAmount = orderPricingDetails?.total;
    }
    setIsloading(true);

    let formData = {
      customerId: authuserdetail?.customer?.customerId,
      itemId: 1,
      appType: "Web",
      invoiceType: "EmptyReturn",
      isRecurring: true,
      numOfDays: Number(Recurring_Order),
      // total: Math.floor(Total_Bill + VoucherDiscount),
      // total: Math.floor(Total_Bill),
      total: orderPricingDetails?.total,
      customerDetailAddressId: selectaddress?.id,
      paymentMethodId: Number(paymentMethod),
      qty: quantity,
      requiredDate: Delivery_Date,
      securityDeposit: 0,
      // price: Math.floor(LPG_Price),
      price: orderPricingDetails?.price,
      amount: orderPricingDetails?.amount,
      mid: "8047317",
      deviceId: navigator?.userAgent,
      currentPricingId: CurrectCityObject?.currentPriceId,
      paidAmount: Number(paidAmount),
      isUrgent: isUrgent,
      mid: "",
      // cardNo: CardNumber,
      cardNo: "NA",
      did: "",
      cid: "",
      mac: "",
      trDateTime: "",
      tid: "",
      voucherId: selectedVoucher ? selectedVoucher?.id : null,
    };

    // thank u modal state
    // setConfirmationModal(true);

    console.log("Badchod Form Data: ", formData);
    dispatch(CreateOrder(formData, resHandler));
  };

  const [Otp, setOtp] = useState("");

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const DigiCashOTPVerifyresHandler = (data) => {
    console.log(
      "DigiCashOTPVerifyresHandler Data: ",
      data?.data?.data?.responseMessage
    );
    if (data?.data?.statusCode === 200) {
      toast.success(data?.data?.data?.responseMessage);
      handleClose();
      setIsloading(false);
      setDigicashOtpModal(false);
      setConfirmationModal(true);
    } else {
      setIsloading(false);
      setOpen(false);
    }
  };

  const handleVerifyClick = () => {
    setIsloading(true);
    let formData = {
      customerId: digicashresdata?.customerId,
      merchantTxn_Id: digicashresdata?.merchantTxn_Id,
      paymentMethod: "DigiCash",
      deviceId: navigator?.userAgent,
      otp: digicashOTP,
      trDateTime: "",
      paymentTransactionId: digicashresdata?.paymentTransactionId,
      orderId: digicashresdata?.orderId,
    };
    dispatch(DigiCashOTPVerify(formData, DigiCashOTPVerifyresHandler));
  };

  const handleConfirmationModalClose = () => {
    dispatch(
      getVouchersByCustomerId(authuserdetail?.customer?.customerId, resHandler)
    );
    // dispatch(
    //   GetCustomerWallet(authuserdetail?.customer?.customerId, resHandler)
    // );
    dispatch(
      GetCustomerScheduledOrder(
        authuserdetail?.customer?.customerId,
        resHandler
      )
    );
    dispatch(
      GetCustomerOrder(authuserdetail?.customer?.customerId, resHandler)
    );
    setConfirmationModal(false);
    navigate("/");
  };

  const matchingPaymentMethod = paymentmethodslist.find(
    (method) => method.id === Number(OrderConfirmationdetails?.paymentmethod)
  );

  // Function to get the current date in yyyy-mm-dd format
  const getCurrentDate = () => {
    const today = new Date();
    const twoDaysLater = new Date(today.setDate(today.getDate()));
    const yyyy = twoDaysLater.getFullYear();
    const mm = String(twoDaysLater.getMonth() + 1).padStart(2, "0");
    const dd = String(twoDaysLater.getDate()).padStart(2, "0");
    const finaldate = `${yyyy}-${mm}-${dd}`;
    return finaldate;
  };

  // Filter to get only the payment method with id 1
  // const filteredPaymentMethods = paymentmethodslist.filter(
  //   (payment) => payment.id !== 3
  // );

  // Filter to get only the payment method with id 1
  const filteredPaymentMethods = paymentmethodslist.filter(
    (payment) => payment.id === 1
  );

  const [count, setCount] = useState(60); // Set initial countdown time to 60 seconds
  const [IsResendButtonDisabled, setIsResendButtonDisabled] = useState(true); // Set initial button state to enabled

  let timer;

  useEffect(() => {
    timer =
      count > 0
        ? setInterval(() => {
            setCount(count - 1);
            if (count === 1) {
              setIsResendButtonDisabled(false); // Enable the button when count reaches 0
            }
          }, 1000)
        : clearInterval(timer);

    return () => clearInterval(timer);
  }, [count]);

  const RegenerateDigiCashOTPresHandler = (data) => {
    if (data) {
      setIsloading(false);
      setCount(60); // Reset the countdown timer to 60 seconds
      setIsResendButtonDisabled(true); // Disable the resend button immediately after resending
    }
  };

  const RegenerateDigiCashOTP = () => {
    setIsloading(true);

    let formData = {
      orderID: Number(createorderdetail?.orderId),
    };

    dispatch(DigiCashRegenerateOTP(formData, RegenerateDigiCashOTPresHandler));
  };

  return (
    <>
      {isLoading ? <Loader /> : null}

      {/* Order Confirmation Modal Start Here */}
      <OrderConfirmationModal
        open={open}
        handleClose={handleClose}
        OrderConfirmationdetails={OrderConfirmationdetails}
        orderPricingDetails={orderPricingDetails}
        isUrgent={isUrgent}
        selectedVoucher={selectedVoucher}
        walletList={walletList}
        matchingPaymentMethod={matchingPaymentMethod}
        CreateNewOrder={CreateNewOrder}
      />
      {/* Order Confirmation Modal End Here */}

      {/* Thank you for your purchase Modal Start Here */}
      <ThankYouModal
        open={confirmationModal}
        onClose={handleConfirmationModalClose}
        orderId={createorderdetail?.orderId}
        quantity={OrderConfirmationdetails?.quantity}
        deliveryDate={OrderConfirmationdetails?.deliverydate}
        paymentMethod={matchingPaymentMethod?.paymentMethod}
        totalBillAmount={Math.max(
          Math.floor(
            orderPricingDetails?.total -
              Math.floor(orderPricingDetails?.discountAmount) -
              walletList?.wallet?.balance
          ),
          0
        )}
        deliveryAddress={OrderConfirmationdetails?.address}
        logoSrc={checkedcylinder}
      />
      {/* Thank you for your purchase Modal End Here */}

      {/* DIGICASH Modal Start Here */}
      <Modal
        open={digicashOtpModal}
        aria-labelledby="second-modal-title"
        aria-describedby="second-modal-description"
      >
        <div className="main_modal_div">
          <Typography
            id="second-modal-title"
            variant="h3"
            sx={{ textAlign: "center", color: "#2b499a", fontWeight: "bold" }}
          >
            DIGICASH
          </Typography>
          <Typography
            id="second-modal-description"
            variant="h5"
            sx={{ textAlign: "center", color: "#2b499a", fontWeight: "bold" }}
          >
            Card Number Verification
          </Typography>
          <Typography
            id="second-modal-description"
            sx={{ textAlign: "center", color: "#2b499a", fontSize: "25px" }}
          >
            Enter the OTP sent to +923362203426
          </Typography>
          <MuiOtpInput
            value={Otp}
            // onChange={handleChange}
            length={6}
            p={6}
            sx={{ borderRadius: "20px" }}
            // value={digicashOTP}
            onChange={(e) => setdigicashOTP(e.target.value)}
          />
          <Typography sx={{ textAlign: "center", color: "#2b499a" }}>
            Please wait for your verification code 00:19
            {/* {count < 10 ? `0${count}` : count} */}
          </Typography>
          <Stack direction="row" justifyContent="center">
            <Typography
              sx={{ textAlign: "center", color: "#2b499a", fontSize: "20px" }}
            >
              Didn't receive OTP?
            </Typography>
            <Button
              sx={{
                color: "#2b499a",
              }}
              disabled={IsResendButtonDisabled}
              onClick={RegenerateDigiCashOTP}
            >
              Resend
            </Button>
          </Stack>
          <Box mt={4} textAlign="center">
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#2d4c9c",
                width: "70%",
                height: "50px",
                borderRadius: "20px",
              }}
              onClick={handleVerifyClick}
            >
              Verify
            </Button>
          </Box>
        </div>
      </Modal>
      {/* DIGICASH Modal End Here */}

      <div className="view_order_detail_div">
        <div className="view_order_detail_inner_header_div">
          <div className="view_order_detail_inner_header_left_div">
            <div className="view_order_detail_inner_header_left_headind_div">
              {itemlist[0]?.name}
            </div>
            <div className="view_order_detail_inner_header_left_text_div">
              {itemlist[0]?.instructions}
            </div>
            <div className="view_order_detail_inner_header_left_price_div">
              RS <span>{CurrectCityObject?.price}</span>
              {/* RS <span>{LPG_Price}</span> */}
            </div>
          </div>
          <div className="view_order_detail_inner_header_right_div">
            <img src={cylinder_img} alt="" />
          </div>
        </div>

        <div className="view_order_detail_inner_body_div">
          <CardContent sx={{ p: { xs: 1, sm: 2 } }}>
            <div className="view_order_detail_inner_body_inner_section_div">
              <div className="view_order_detail_inner_body_inner_section_heading_div">
                Delivery Date
              </div>
              <div>
                <input
                  type="date"
                  id=""
                  min={getCurrentDate()}
                  value={Delivery_Date}
                  onChange={(e) => setDelivery_Date(e.target.value)}
                />
              </div>
            </div>
            {/* <Divider sx={{ marginTop: "15px", marginBottom: "15px" }} /> */}

            <div className="view_order_detail_inner_body_inner_section_div">
              <div>
                <div style={{ fontSize: ".9rem", color: "#1591c7" }}>
                  Please specify number of days for
                </div>
                <div className="view_order_detail_inner_body_inner_section_heading_div">
                  Recurring Order
                </div>
              </div>
              <div>
                <input
                  type="number"
                  placeholder="Days"
                  id=""
                  value={Recurring_Order}
                  onChange={(e) => setRecurring_Order(e.target.value)}
                  min="1"
                />
              </div>
            </div>
            {/* <Divider sx={{ marginTop: "15px", marginBottom: "15px" }} /> */}

            <div className="view_order_detail_inner_body_inner_section_div">
              <div className="view_order_detail_inner_body_inner_section_heading_div">
                Quantity
              </div>
              <div>
                <div className="main_add_quantity_div">
                  <div>
                    <FaMinus onClick={() => plusHandlerAndMinus(-1)} />
                  </div>
                  <div>{quantity}</div>
                  <div>
                    <FaPlus onClick={() => plusHandlerAndMinus(1)} />
                  </div>
                </div>
              </div>
            </div>
            <Divider sx={{ marginTop: "15px", marginBottom: "15px" }} />

            <div className="view_order_detail_inner_body_inner_section_div">
              <div className="view_order_detail_inner_body_inner_section_heading_div">
                Payment
              </div>
              <div></div>
            </div>
            <br />
            <div className="view_order_detail_inner_body_payment_section_div">
              {filteredPaymentMethods.map((payment, index) => (
                <div key={index}>
                  <label htmlFor={payment.id}>
                    <input
                      type="radio"
                      name="Payment"
                      id={payment.id}
                      value={payment.id}
                      // checked={paymentMethod === payment.paymentMethod}
                      onChange={handlePaymentMethodChange}
                      className="custom-radio"
                    />
                    {payment.paymentMethod}
                  </label>
                </div>
              ))}
            </div>
            {ShowCardNumber ? (
              <div>
                <Divider sx={{ marginTop: "30px" }} />
                <br />
                <h5>Card No</h5>
                <TextField
                  label="Card Number"
                  variant="outlined"
                  // InputLabelProps={{
                  //     shrink: true,
                  // }}
                  style={{ width: "100%" }}
                  value={CardNumber}
                  onChange={(e) => setCardNumber(e.target.value)}
                />
              </div>
            ) : (
              <></>
            )}

            <TableContainer
              sx={{
                borderRadius: "10px",
                boxShadow: "0px 0px 5px #ecdcdc",
                marginTop: "15px",
              }}
            >
              <Table aria-label="order details table">
                <TableHead sx={{ height: "5px", color: "#0a74b9" }}>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        // borderBottom: "2px solid black",
                        color: "#0a74b9",
                        border: "none",
                      }}
                    >
                      Order Details
                    </TableCell>
                    {/* <TableCell
                                                align="right"
                                                sx={{
                                                    fontSize: "15px",
                                                    fontWeight: "bold",
                                                    borderBottom: "2px solid black",
                                                }}
                                            >
                                                Unit Price
                                            </TableCell> */}
                    <TableCell
                      align="right"
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        // borderBottom: "2px solid black",
                        color: "#0a74b9",
                        border: "none",
                      }}
                    >
                      Qty
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        // borderBottom: "2px solid black",
                        color: "#0a74b9",
                        border: "none",
                      }}
                    >
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ border: "none" }}
                    >
                      LPG Price Including GST
                    </TableCell>
                    <TableCell align="right" sx={{ border: "none" }}>
                      {quantity}
                    </TableCell>
                    {/* <TableCell align="right">{LPG_Price !== null ? LPG_Price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0"}</TableCell> */}
                    {/* <TableCell align="right">{LPG_Price}</TableCell> */}
                    <TableCell align="right" sx={{ border: "none" }}>
                      {orderPricingDetails?.amount +
                        orderPricingDetails?.gstTax}
                    </TableCell>
                  </TableRow>

                  {/* <TableRow>
                                            <TableCell component="th" scope="row">GST ({CurrectCityObject?.gstTax + "%"})</TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">{GST}</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell component="th" scope="row">Consumer Price</TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">{Consumer_Price !== null ? Consumer_Price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0"}</TableCell>
                                        </TableRow> */}

                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ border: "none" }}
                    >
                      <Stack
                        direction={isSmallScreen ? "column" : "row"}
                        alignItems={isSmallScreen ? "flex-start" : "center"}
                      >
                        <Box>Delivery Charges</Box>
                      </Stack>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ border: "none" }}
                    ></TableCell>
                    {/* <TableCell align="right">
                        {Delivery_Charges !== null
                          ? Math.floor(orderPricingDetails?.deliveryCharges)
                              .toFixed(0)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : "0"}
                      </TableCell> */}
                    <TableCell align="right" sx={{ border: "none" }}>
                      {Math.floor(orderPricingDetails?.deliveryCharges)
                        .toFixed(0)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ border: "none" }}
                    >
                      <Stack
                        direction={isSmallScreen ? "column" : "row"}
                        alignItems={isSmallScreen ? "flex-start" : "center"}
                      >
                        <Box>Urgent Delivery Charges</Box>
                      </Stack>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ border: "none" }}
                    ></TableCell>
                    {/* <TableCell align="right">
                        {Delivery_Charges !== null
                          ? Math.floor(orderPricingDetails?.deliveryCharges)
                              .toFixed(0)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : "0"}
                      </TableCell> */}
                    <TableCell align="right" sx={{ border: "none" }}>
                      {Math.floor(orderPricingDetails?.urgentDeliveryCharges)
                        .toFixed(0)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Stack
              direction={isSmallScreen ? "column" : "row"}
              alignItems={isSmallScreen ? "flex-start" : "center"}
              my={3}
            >
              <label
                style={{
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                  margin: 0,
                  border: isSmallScreen ? "" : "2px solid #0a74b9",
                  // border: "2px solid black",
                  padding: isSmallScreen ? "2px" : "5px 15px",
                  borderRadius: isSmallScreen ? "5px" : "20px",
                  boxShadow: isSmallScreen ? "" : "1px 1px 5px lightgray",
                  // marginLeft: isSmallScreen ? "" : 10,
                  // marginTop: isSmallScreen ? 5 : "",
                  fontSize: isSmallScreen ? "10px" : "12px",
                  minWidth: "100px",
                }}
              >
                {quantity > 0 && (
                  <Checkbox
                    name="deliveryType"
                    value="Urgent"
                    onChange={() =>
                      handleDeliveryType(
                        orderPricingDetails?.urgentDeliveryCharges
                      )
                    }
                    inputProps={{ "aria-label": "Urgent Delivery" }}
                    // size={isSmallScreen ? "" : "small"} // This makes the checkbox smaller
                    size={"small"} // This makes the checkbox smaller
                    sx={{
                      padding: 0,
                      marginRight: isSmallScreen ? "2px" : 1,
                      color: "#0a74b9", // Default color
                      "&.Mui-checked": {
                        color: "#0a74b9", // Color when checked
                      },
                    }}
                  />
                )}
                {isSmallScreen
                  ? `Urgent Delivery Rs ${Urgent_Delivery}`
                  : `Urgent Delivery Charges Rs ${Urgent_Delivery}`}
              </label>
            </Stack>

            <Stack
              sx={{
                borderRadius: "10px",
                boxShadow: "0px 0px 5px #ecdcdc",
                marginTop: "15px",
                padding: "16px",
              }}
              flexDirection={"column"}
              gap={2}
            >
              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-between",
                  // marginTop: "15px",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>Sub Total</Typography>
                <Typography>
                  Rs{" "}
                  {Math.floor(
                    orderPricingDetails?.total -
                      orderPricingDetails?.securityDeposit
                  )
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Typography>Security Deposit</Typography>
                <Typography>
                  {Math.floor(orderPricingDetails?.securityDeposit)
                    ?.toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>
              </Stack>
              {discountedVouchersList &&
                walletList?.wallet?.balance < orderPricingDetails?.total && (
                  <Stack>
                    {discountedVouchersList?.length === 1 &&
                    selectedVoucher ? null : discountedVouchersList?.length >
                        0 &&
                      // && Total_Bill
                      orderPricingDetails?.total > 0 ? (
                      <>
                        {!selectedVoucher && (
                          <Box
                            sx={{
                              fontSize: { xs: "13px", sm: "15px" },
                              fontWeight: "bold",
                              cursor: "pointer",
                              // color: "#0A74B9",
                              // textDecoration: "underline",
                              display: "flex",
                              alignItems: "center",
                              // gap: 2,
                            }}
                            onClick={handleVoucherOpen}
                          >
                            <img
                              src={voucherImg}
                              width={isSmallScreen ? 30 : 40}
                            />
                            Apply a Voucher
                          </Box>
                        )}
                      </>
                    ) : null}
                    {voucherOpen && (
                      <DiscountedVoucherModal
                        open={voucherOpen}
                        handleClose={handleVoucherClose}
                        handleVoucherSelected={handleVoucherSelected}
                        // Total_Bill={LPG_Price}
                        Total_Bill={orderPricingDetails?.price * quantity}
                        selectedVoucherId={selectedVoucher?.id}
                      />
                    )}
                    {selectedVoucher ? (
                      <VoucherCard
                        discountValue={selectedVoucher?.discountValue}
                        name={selectedVoucher?.name}
                        discountAmount={orderPricingDetails?.discountAmount}
                        isSmallScreen={isSmallScreen}
                        onRemove={handleVoucherRemove}
                      />
                    ) : null}
                  </Stack>
                )}
              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>Total Bill</Typography>
                <Typography>
                  Rs{" "}
                  {Math.floor(
                    orderPricingDetails?.total -
                    Math.floor(orderPricingDetails?.discountAmount)
                  )
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>
              </Stack>
              {selectedVoucher ? (
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ textDecoration: "underline" }}>
                    See Summary
                  </Typography>
                  <Typography
                    sx={{
                      textDecoration: "line-through",
                    }}
                  >
                    Rs{" "}
                    {Math.floor(orderPricingDetails?.total)
                      .toFixed(0)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography>
                </Stack>
              ) : null}
              {walletList?.wallet?.balance > 0 ? (
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      // fontWeight: "bold",
                      fontSize: { xs: "12px", sm: "15px" },
                    }}
                  >
                    {" "}
                    Wallet{" "}
                  </Typography>
                  <Typography align="right"></Typography>
                  <Typography
                    sx={{
                      // fontWeight: "bold",
                      fontSize: { xs: "12px", sm: "15px" },
                    }}
                  >
                    - Rs.{" "}
                    {walletList?.wallet?.balance
                      .toFixed(0)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography>
                </Stack>
              ) : null}
            </Stack>

            <Stack
              sx={{
                borderRadius: "10px",
                boxShadow: "0px 0px 5px #ecdcdc",
                marginTop: "15px",
                padding: "16px",
              }}
              flexDirection={"column"}
              gap={3}
            >
              {walletList?.wallet?.balance > 0 ? (
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: "12px", sm: "15px" },
                    }}
                  >
                    {" "}
                    Net Payable{" "}
                  </Typography>
                  <Typography align="right"></Typography>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: "12px", sm: "15px" },
                    }}
                  >
                    Rs.{" "}
                    {Math.max(
                      Math.floor(
                        orderPricingDetails?.total -
                          orderPricingDetails?.discountAmount -
                          walletList?.wallet?.balance
                      ),
                      0
                    )
                      .toFixed(0)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography>
                </Stack>
              ) : null}
              <Stack>
                <Typography sx={{ color: "#1591c7", fontSize: "16px" }}>
                  Delievery Address
                </Typography>
                <Typography sx={{ color: "#818181" }}>
                  {selectaddress?.address}
                </Typography>
              </Stack>
              <div className="main_proceed_to_checkout_div">
                <Button variant="contained" onClick={handleOpen}>
                  Proceed to Checkout
                </Button>
              </div>
            </Stack>
          </CardContent>
        </div>
      </div>
    </>
  );
}

export default Refill;
