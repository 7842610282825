import React, { useState } from "react";
import {
  Box,
  Container,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
} from "@mui/material";
import { Add, Close, Delete, PlusOne } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { becomeInfluencerRequest } from "../../../redux/store/action/unauth-action/discounted_voucher-action/discounted_voucher-action";
import { toast } from "react-toastify";

const Form2 = ({ handleChange, handleFormChange, formData, setFormData }) => {
  const dispatch = useDispatch();
  const { authuserdetail } = useSelector(({ authStates }) => authStates);
  const { influencerCategories } = useSelector(
    ({ discountedVoucherStates }) => discountedVoucherStates
  );
  console.log("formData:::::::::::: ", formData);

  // const handleAddPlatform = () => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     socialMediaPlatformDetails: [
  //       ...prevData.socialMediaPlatformDetails,
  //       {
  //         id: prevData.socialMediaPlatformDetails.length + 1,
  //         platform: "",
  //         link: "",
  //         followers: null,
  //       },
  //     ],
  //   }));
  // };

  const handleAddPlatform = () => {
    setFormData((prevData) => {
      const newId =
        prevData.socialMediaPlatformDetails.reduce(
          (maxId, item) => Math.max(maxId, item.id),
          0
        ) + 1;

      return {
        ...prevData,
        socialMediaPlatformDetails: [
          ...prevData.socialMediaPlatformDetails,
          {
            id: newId,
            platform: "",
            link: "",
            followers: "",
          },
        ],
      };
    });
  };

  const handleDeletePlatform = (id) => {
    setFormData((prevData) => ({
      ...prevData,
      socialMediaPlatformDetails: prevData.socialMediaPlatformDetails.filter(
        (item, i) => item.id !== id
      ),
    }));
  };

  const resHandler = ({ data }) => {
    if (data && data.statusCode === 200) {
      toast.success(data.message);
      setFormData({
        name: authuserdetail?.customer?.name,
        email: authuserdetail?.customer?.email,
        phone: authuserdetail?.customer?.phone,
        description: "",
        area: "",
        socialMediaPlatformDetails: [
          {
            id: 1,
            platform: "",
            link: "",
            followers: 0,
          },
        ],
        category: "",
        age: null,
        gender: "",
      });
      handleFormChange(1);
    } else {
      toast.error(data.message);
    }
  };

  const validateForm = () => {
    // Name Validation
    if (!formData.name) {
      toast.error("Name is required!");
      return false;
    }

    // Email Validation
    if (!formData.email || !/^\S+@\S+\.\S+$/.test(formData.email)) {
      toast.error("Valid email is required!");
      return false;
    }

    // Phone Validation
    if (!formData.phone || formData.phone.length < 10) {
      toast.error("Valid phone number is required!");
      return false;
    }

    // Description Validation
    if (!formData.description) {
      toast.error("Description is required!");
      return false;
    }

    // Area Validation
    if (!formData.area) {
      toast.error("Area is required!");
      return false;
    }

    // Social Media Platforms Validation
    for (const platform of formData.socialMediaPlatformDetails) {
      if (!platform.platform) {
        toast.error("Social media platform name is required!");
        return false;
      }
      if (!platform.link) {
        toast.error("Social media link is required!");
        return false;
      }
      if (platform.followers < 0) {
        toast.error("Followers count cannot be negative!");
        return false;
      }
    }

    // Category Validation
    if (!formData.category) {
      toast.error("Category is required!");
      return false;
    }

    // Age Validation
    if (formData.age === null || formData.age < 0) {
      toast.error("Valid age is required!");
      return false;
    }

    // Gender Validation
    if (!formData.gender) {
      toast.error("Gender is required!");
      return false;
    }

    return true; // All validations passed
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const transformedPayload = {
        customerId: authuserdetail?.customer?.customerId, // Assuming default or specific customerId, replace if needed
        imageUrl: "", // Add the image URL if available
        description: formData.description || "string",
        // followersCount: formData.socialMediaPlatformDetails.reduce(
        //   (acc, platform) => acc + platform.followers,
        //   0
        // ),
        lat: "", // Add the latitude if available
        long: "", // Add the longitude if available
        age: formData.age || 0,
        gender: formData.gender || "string",
        category: formData.category || 0,
        socialMediaLinks: formData.socialMediaPlatformDetails.map(
          (platform) => ({
            link: platform.link || "string",
            platform: platform.platform || "string",
            followersCount: platform.followers || 0,
          })
        ),
      };

      dispatch(becomeInfluencerRequest(transformedPayload, resHandler));
    }
  };
  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // p: 3,
          backgroundColor: "#fff",
        }}
      >
        {formData.socialMediaPlatformDetails.map((item, index) => (
          <Box key={index} sx={{ width: "100%", my: 2, position: "relative" }}>
            <FormControl fullWidth margin="normal">
              <InputLabel sx={{ backgroundColor: "white", padding: "0 5px" }}>
                Social Media Platform
              </InputLabel>
              <Select
                // name="platform"
                value={item.platform}
                // onChange={(e) => {
                //   const newPlatforms = [...platforms];
                //   newPlatforms[index].platform = e.target.value;
                //   setPlatforms(newPlatforms);
                // }}
                onChange={(e) => handleChange(e, index, "platform")}
              >
                <MenuItem value="Facebook">Facebook</MenuItem>
                <MenuItem value="Instagram">Instagram</MenuItem>
                <MenuItem value="Twitter">Twitter</MenuItem>
                <MenuItem value="Other">Others</MenuItem>
              </Select>
            </FormControl>

            <TextField
              name="link"
              label="Social Media Link"
              variant="outlined"
              fullWidth
              margin="normal"
              value={item.link}
              // onChange={(e) => {
              //   const newPlatforms = [...platforms];
              //   newPlatforms[index].link = e.target.value;
              //   setPlatforms(newPlatforms);
              // }}
              onChange={(e) => handleChange(e, index, "link")}
            />

            <TextField
              name="followers"
              type="number"
              label="Followers Count"
              variant="outlined"
              fullWidth
              margin="normal"
              value={item.followers}
              onChange={(e) => handleChange(e, index, "followers")}
              // onChange={(e) => {
              //   const newPlatforms = [...platforms];
              //   newPlatforms[index].followers = e.target.value;
              //   setPlatforms(newPlatforms);
              // }}
            />

            {formData.socialMediaPlatformDetails.length > 1 && (
              <IconButton
                onClick={() => handleDeletePlatform(item.id)}
                sx={{
                  color: "red",
                  position: "absolute",
                  bottom: "-40px",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <Close />
              </IconButton>
            )}
          </Box>
        ))}

        <Button
          component="button"
          variant="text"
          onClick={handleAddPlatform}
          sx={{ mt: 3 }}
        >
          <Add /> Add Another Platform
        </Button>

        <FormControl fullWidth margin="normal">
          <InputLabel sx={{ backgroundColor: "white", padding: "0 5px" }}>
            Category
          </InputLabel>
          <Select
            name="category"
            required
            value={formData.category}
            onChange={handleChange}
          >
            {influencerCategories?.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          name="age"
          type="number"
          label="Age"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.age}
          onChange={handleChange}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel sx={{ backgroundColor: "white", padding: "0 5px" }}>
            Gender
          </InputLabel>
          <Select name="gender" value={formData.gender} onChange={handleChange}>
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </FormControl>

        <Box
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            width: "100%",
            mt: 3,
          }}
        >
          <Button
            variant="contained"
            color="warning"
            sx={{ width: "100%", backgroundColor: "#FCCC2E" }}
            onClick={() => handleFormChange(1)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: "100%" }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Form2;
