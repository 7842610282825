import React from "react";
import discountVoucherImage from "../assets/voucherImg.png";
import giftImage from "../assets/vocuherPopUpGift.png";
import cardImage from "../assets/voucherPopupCard.png";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./styles/DiscountVoucherPopup.module.css"; // Import the CSS module

const DiscountVoucherPopUp = ({ handleStopDiscountVoucherPopup }) => {
  return (
    <div className={styles.urgentDC_PopUp}>
      <div className={styles.urgentDC_PopUp_Container}>
        <img
          src={giftImage} // Update path
          alt="Urgent"
          style={{ width: "100%", height: "auto", marginTop: "20px" }} // Adjusted for responsiveness
        />
        <button
          onClick={handleStopDiscountVoucherPopup}
          className={styles.closeBtn}
        >
          <CloseIcon style={{ fontSize: 24, color: "white" }} />
        </button>
        <div className={styles.applyText}>
          Discount Voucher <br /> Available!
        </div>
        <div className={styles.voucherCard}>
          <img
            src={cardImage} // Update path
            alt="Urgent"
            style={{ width: "100%", height: "auto" }} // Adjusted for responsiveness
          />
          <p className={styles.purchaseText}>
            Want to Make Your Purchase Smarter?
          </p>
        </div>
        <p className={styles.instantSavings}>
          Use your voucher at checkout for instant savings and get the value you
          deserve!
        </p>
      </div>
    </div>
  );
};

export default DiscountVoucherPopUp;
