import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import zIndex from "@mui/material/styles/zIndex";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Grid,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { getVouchersByCustomerId } from "../redux/store/action/unauth-action/discounted_voucher-action/discounted_voucher-action";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { FaTags } from "react-icons/fa";
import { Close } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  maxHeight: "90vh",
  minHeight: "45vh",
  overflowY: "auto",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  paddingY: "25px",
};

const styleMediumScreen = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxHeight: "90vh",
  minHeight: "45vh",
  overflowY: "auto",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  paddingY: "25px",
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function DontHaveVouchers() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        padding: 2,
      }}
    >
      <Typography
        variant="h5"
        textAlign="center"
        sx={{
          color: "gray",
          width: "100%",
          fontSize: "4rem",
        }}
      >
        <FaTags />
      </Typography>
      <Typography
        variant="h5"
        textAlign="center"
        sx={{
          color: "gray",
          width: "100%",
          fontSize: "1.5rem",
        }}
      >
        Sorry! <br /> You don't have any voucher
      </Typography>
    </Box>
  );
}

const DiscountedVoucherModal = ({
  open,
  handleClose,
  handleVoucherSelected,
  Total_Bill,
  selectedVoucherId,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { discountedVouchersList } = useSelector(
    ({ discountedVoucherStates }) => discountedVoucherStates
  );
  const { selectaddress } = useSelector(({ addressStates }) => addressStates);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const currentDate = new Date();

  // const filterVouchersByType = (voucherTypes) => {
  //   return discountedVouchersList.filter((voucher) => {
  //     const applicableFromDate = new Date(voucher.applicableFrom);
  //     console.log(voucherTypes.includes(voucher.voucherType));
  //     return (
  //       voucherTypes.includes(voucher.voucherType) && // Multiple voucher types check
  //       applicableFromDate <= currentDate &&
  //       voucher.applicableRegion === selectaddress.city
  //     );
  //   });
  // };

  // console.log(
  //   "discountedVouchersList:::::::::::::::::::? ",
  //   discountedVouchersList
  // );

  // Filter for multiple voucher types
  // const discountVoucher = filterVouchersByType(["Discount", "SignUp"]);
  // const referrerVoucher = filterVouchersByType([
  //   "Referrer",
  //   "Referee",
  //   "InfluencerReferee",
  // ]);
  // const refereeVoucher = filterVouchersByType("Referee");
  // const influencerVoucher = filterVouchersByType("InfluencerReferee");

  const currentDate = new Date();

  const discountVoucher = discountedVouchersList.filter((voucher) => {
    const applicableFromDate = new Date(voucher.applicableFrom);
    console.log(
      "applicableFromDate <= currentDate::::::: ",
      applicableFromDate <= currentDate,
      applicableFromDate,
      currentDate,
      voucher.applicableRegion === selectaddress.city ||
        voucher.applicableRegion === null
    );
    return (
      (voucher.voucherType === "Discount" ||
        voucher.voucherType === "SignUp") &&
      applicableFromDate <= currentDate &&
      (voucher.applicableRegion === selectaddress.city ||
        voucher.applicableRegion === null)
    );
  });
  console.log("Badchod Discount Voucher List:::::: ", discountedVouchersList);

  const referrerVoucher = discountedVouchersList.filter((voucher) => {
    const applicableFromDate = new Date(voucher.applicableFrom);
    return (
      (voucher.voucherType === "Referrer" ||
        voucher.voucherType === "Referee" ||
        voucher.voucherType === "InfluencerReferee") &&
      applicableFromDate <= currentDate &&
      (voucher.applicableRegion === selectaddress.city ||
        voucher.applicableRegion === null)
    );
  });

  // const refereeVoucher = discountedVouchersList.filter((voucher) => {
  //   const applicableFromDate = new Date(voucher.applicableFrom);
  //   return (
  //     voucher.voucherType === "Referee" && applicableFromDate <= currentDate
  //   );
  // });

  // const influencerVoucher = discountedVouchersList.filter((voucher) => {
  //   const applicableFromDate = new Date(voucher.applicableFrom);
  //   return (
  //     voucher.voucherType === "InfluencerReferee" &&
  //     applicableFromDate <= currentDate
  //   );
  // });

  // const filteredDiscountedVouchers = discountedVouchersList?.filter(
  //   (voucher) => {
  //     return voucher?.id !== selectedVoucherId;
  //   }
  // );

  const filteredDiscountedVouchers = (
    value === 0 ? discountVoucher : value === 1 ? referrerVoucher : []
  )?.filter((voucher) => {
    return voucher?.id !== selectedVoucherId;
  });

  const TabLabel = ({
    text,
    count,
    bgColor = "#0A74B9",
    textColor = "#fff",
  }) => {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        {text}
        <span
          style={{
            backgroundColor: bgColor,
            color: textColor,
            borderRadius: "2px",
            width: "24px",
            height: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "14px",
          }}
        >
          {count}
        </span>
      </div>
    );
  };

  const vouchers = filteredDiscountedVouchers?.map((data, index) => (
    <Grid item xs={12} key={data?.id} mb={2}>
      <Stack
        direction="row"
        width={"100%"}
        height={"130px"}
        // borderRadius={"20px"}
        // overflow={"hidden"}
        boxShadow={"1px 1px 10px gray"}
      >
        <Stack
          sx={{ backgroundColor: "#FCCC2E", position: "relative" }}
          direction="row"
          alignItems="center"
        >
          <Stack
            direction={"column"}
            justifyContent={"space-around"}
            alignItems={"center"}
            sx={{
              position: "absolute",
              left: "-10px",
              top: "10px",
              bottom: "10px",
              zIndex: 50,
            }}
          >
            {Array.from({ length: 4 }).map((_, index) => (
              <Box
                key={index}
                sx={{
                  backgroundColor: "white",
                  width: "17px",
                  height: "10px",
                  borderRadius: "50%",
                  // boxShadow:
                  //   "inset 1px 1px 5px gray , inset -1px -1px 5px white",
                }}
              ></Box>
            ))}
          </Stack>
          <Typography
            variant="h7"
            fontWeight="bold"
            sx={{ transform: "rotate(270deg)" }}
          >
            {data?.discountValue}% off
          </Typography>
        </Stack>
        <Stack
          flex={1}
          direction={"column"}
          justifyContent={"space-between"}
          px={4}
          pt={2}
        >
          <Stack flex={1} borderBottom={"2px dashed lightgray"}>
            <Typography
              variant="h7"
              color="#0A74B9"
              fontSize="20px"
              fontWeight={"bold"}
            >
              {data?.name}
            </Typography>
            <Typography variant="h7" color="#9b9b9b" fontSize="10px">
              Max Capped Amount {data?.discountCapAmount}
            </Typography>
            <Typography
              variant="h7"
              // color="#9b9b9b"
              color={`${
                Total_Bill >= data?.minimumOrderAmount ? "#9b9b9b" : "red"
              }`}
              fontSize="10px"
              mb={1}
            >
              Min.order Rs {data?.minimumOrderAmount}
            </Typography>
          </Stack>
          <Stack
            flex={1}
            alignItems={"center"}
            direction={"row"}
            justifyContent={"space-between"}
          >
            {!isSmallScreen && (
              <Typography
                variant="h7"
                color="#0A74B9"
                fontSize="15px"
                fontWeight={"bold"}
                textAlign={"end"}
              >
                Voucher Applicable from{" "}
                {moment(data?.applicableFrom).format("DD/MM/YY")} to{" "}
                {moment(data?.applicableTo).format("DD/MM/YY")}
              </Typography>
            )}
            {isSmallScreen && (
              <Typography
                variant="h6"
                color="#0A74B9"
                fontSize="12px"
                fontWeight={"bold"}
                textAlign={"end"}
              >
                {moment(data?.applicableFrom).format("DD/MM/YY")} to{" "}
                {moment(data?.applicableTo).format("DD/MM/YY")}
              </Typography>
            )}
            {console.log("data?.minimumOrderAmount ", data?.minimumOrderAmount)}
            {Total_Bill >= data?.minimumOrderAmount && (
              <Button
                variant="text"
                color="warning"
                // fontSize="15px"
                // textAlign={"end"}
                // sx={{ color: "#FCCC2E" }}
                onClick={() => handleVoucherSelected(data)}
              >
                Apply{" "}
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Grid>
  ));

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={isMediumScreen ? styleMediumScreen : style}>
          <Stack rowGap={5}>
            <Box sx={{ flexGrow: 1 }}>
              <IconButton
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  zIndex: 200,
                  right: 8,
                  top: 10,
                  color: "#fff",
                  backgroundColor: "#dc0000",
                  ":hover": {
                    backgroundColor: "#dc0000", // Keeps the background red on hover
                  },
                }}
              >
                <Close />
              </IconButton>
              <Grid container spacing={2}>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      variant="fullWidth"
                    >
                      <Tab
                        label={
                          <TabLabel
                            text="Voucher"
                            count={discountVoucher.length}
                          />
                        }
                        style={{ textTransform: "capitalize" }}
                        {...a11yProps(0)}
                      />
                      <Tab
                        label={
                          <TabLabel
                            text="Referral Voucher"
                            count={referrerVoucher.length}
                          />
                        }
                        style={{ textTransform: "capitalize" }}
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </Box>
                  <CustomTabPanel
                    value={value}
                    index={0}
                    style={{ padding: "10px" }}
                  >
                    {vouchers?.length > 0 ? vouchers : <DontHaveVouchers />}
                  </CustomTabPanel>
                  <CustomTabPanel
                    value={value}
                    index={1}
                    style={{ padding: "10px" }}
                  >
                    {vouchers?.length > 0 ? vouchers : <DontHaveVouchers />}
                  </CustomTabPanel>
                </Box>
              </Grid>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default DiscountedVoucherModal;
