import React from "react";
import {
  Modal,
  Box,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import dayjs from "dayjs";

const ThankYouModal = ({
  open,
  onClose,
  orderId,
  quantity,
  deliveryDate,
  paymentMethod,
  totalBillAmount,
  deliveryAddress,
  qrCodeMessage = "Kindly show the QR code at the time of delivery",
  logoSrc,
}) => {
  return (
    <Modal
      open={open}
      aria-labelledby="thank-you-modal-title"
      aria-describedby="thank-you-modal-description"
    >
      <div className="main_modal_div">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            className="blue_logo"
            src={logoSrc}
            alt="Order Placed"
            style={{ width: "100px", height: "100px" }}
          />
        </Box>
        <Typography
          id="thank-you-modal-title"
          variant="h4"
          sx={{
            textAlign: "center",
            color: "#1591c7",
            fontWeight: "bold",
            fontSize: "23px",
          }}
        >
          Thank you for your purchase
        </Typography>
        <Typography
          id="thank-you-modal-description"
          sx={{ textAlign: "center", fontSize: "17px" }}
        >
          Your order {orderId} has been received
        </Typography>
        <Typography
          sx={{ textAlign: "center", fontSize: "13px" }}
        >
          You will get a confirmation shortly
        </Typography>

        <Stack marginTop={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography color="#818181">Quantity</Typography>
            <Typography color="#1591c7">{quantity}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" marginTop={2}>
            <Typography color="#818181">Delivery Date</Typography>
            <Typography color="#1591c7">
              {dayjs(deliveryDate).format("DD-MM-YYYY")}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" marginTop={2}>
            <Typography color="#818181">Payment Mode</Typography>
            <Typography color="#1591c7">{paymentMethod}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" marginTop={2}>
            <Typography color="#818181">Total Bill Amount</Typography>
            <Typography sx={{ color: "#1591c7" }}>
              Rs.{" "}
              {totalBillAmount
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" marginTop={2}>
            <Typography color="#818181">Delivery Address</Typography>
            <Stack>
              <Typography color="#1591c7">{deliveryAddress}</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Typography
          sx={{ textAlign: "center", marginTop: "20px", fontSize: "20px" }}
        >
          {qrCodeMessage}
        </Typography>
        <Box mt={2} textAlign="center">
          <Button
            variant="contained"
            sx={{
              backgroundColor: "primary",
              width: "70%",
              height: "50px",
              borderRadius: "20px",
            }}
            onClick={onClose}
          >
            Back To Home
          </Button>
        </Box>
      </div>
    </Modal>
  );
};

export default ThankYouModal;
