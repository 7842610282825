import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { SelectAddress } from "../redux/store/action/unauth-action/address-action/address-action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxHeight: "90vh",
  overflowY: "auto",
  outline: "none",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ChangeAddressModal = ({ open, handleClose }) => {
  const { addressList } = useSelector(
    ({ authStates }) => authStates
  );
  const dispatch = useDispatch();

  const SelectAddressfunction = (address) => {
    dispatch(SelectAddress(address));
    handleClose()
  };

  const addresses = addressList.map((address, index) => (
    <div key={index} className="main_manage_address_card_div" style={{margin: "10px 0"}}>
      <div
        className="main_manage_address_card_body_div"
        onClick={() => SelectAddressfunction(address)}
      >
        <div className="main_manage_address_card_header_heading_div">
          {address.type}
        </div>
        <div>
          {" "}
          <b style={{ color: "black" }}>Address: </b> {address.address}
        </div>
        <div>
          <b style={{ color: "black" }}>province: </b>
          {address.province}
        </div>
        <div>
          <b style={{ color: "black" }}>City: </b>
          {address.city}
        </div>
      </div>
      {/* <div className="main_manage_address_card_header_div">
        <div className="main_manage_address_card_header_edit_div">
          <MdModeEdit
            onClick={() => manageaddress("Update Address", address)}
          />
        </div>
      </div> */}
    </div>
  ));

  console.log("addressList", addressList);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {addresses}
        <Box></Box>
        {/* <Typography id="modal-modal-title" variant="h6" component="h2">
          Text in a modal
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
        </Typography> */}
      </Box>
    </Modal>
  );
};

export default ChangeAddressModal;
