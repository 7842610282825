import React from "react";
import urgentImage from "../assets/urgent_dc.png";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./styles/UrgentDCModal.module.css"; // Import the CSS module

const UrgentDCModal = ({ handleStopIsUrgentView }) => {
  return (
    <div className={styles.urgentDC_PopUp}>
      <div className={styles.urgentDC_PopUp_Container}>
        <img
          src={urgentImage} // Update path
          alt="Urgent"
          style={{ width: "100%", height: "auto" }} // Adjusted for responsiveness
        />
        <button onClick={handleStopIsUrgentView} className={styles.closeBtn}>
          <CloseIcon style={{ fontSize: 24, color: "black" }} />
        </button>
      </div>
    </div>
  );
};

export default UrgentDCModal;
