import {
  Avatar,
  Box,
  Button,
  Card,
  CardMedia,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import background_img from "../../../../src/assets/background_img.png";
import user__img from "../../../../src/assets/user_img.png";
import { Link, useNavigate } from "react-router-dom";
import { FormLabel, IconButton } from "@mui/joy";
import { useEffect, useState } from "react";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdateUserImage,
  UpdateUserInformation,
} from "../../../redux/store/action/unauth-action/myprofil-action/myprofil-action";
import { GetCustomerDetails } from "../../../redux/store/action/auth-action/auth-action";
import { toast } from "react-toastify";
import Loader from "../../../Loader/Loader";

export const MyProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(false);

  const { userId, authuserdetail } = useSelector(
    ({ authStates }) => authStates
  );

  // console.log("authuserdetail");
  // console.log(authuserdetail);

  const [imageSrc, setImageSrc] = useState(
    authuserdetail?.base64Image || user__img
  );

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [date_of_birth, setdate_of_birth] = useState("");
  const [issueDate, setissueDate] = useState("");
  const [cnic, setcnic] = useState("");
  const [cnic_expiry, setcnic_expiry] = useState("");

  const currentDate = new Date();

  const handleChange = (e) => {
    const selectedDate = new Date(e.target.value);

    if (selectedDate >= currentDate) {
      toast.error("Please select a Correct Date of Birth!");
      setdate_of_birth("");
    } else {
      setdate_of_birth(e.target.value);
    }
  };

  useEffect(() => {
    if (authuserdetail?.customer) {
      console.log("authuserdetail?.customer", authuserdetail?.customer);
      setname(authuserdetail?.customer?.name);
      setemail(authuserdetail?.customer?.email);
      setdate_of_birth(authuserdetail?.customer?.dateOfBirth);
      setcnic(authuserdetail?.customer?.cnic);
      setcnic_expiry(authuserdetail?.customer?.cnicExpiryDate);
      setissueDate(authuserdetail?.customer?.cnicIssueDate);
    }
  }, [authuserdetail?.customer]);

  // useEffect(() => {
  //   const savedImageSrc = localStorage.getItem("profileImageSrc");
  //   if (savedImageSrc) {
  //     setImageSrc(savedImageSrc);
  //   }
  // }, []);

  const resHandler = (data) => {
    if (data) {
      dispatch(GetCustomerDetails(userId));
      setIsloading(false);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const editedImageSrc = e.target.result;
        setImageSrc(editedImageSrc);

        setIsloading(true);
        let formData = {
          userId: authuserdetail?.userId,
          base64Image: editedImageSrc,
        };

        dispatch(UpdateUserImage(formData, resHandler));

        // localStorage.setItem("profileImageSrc", editedImageSrc);
      };
      reader.readAsDataURL(file);
    }
  };

  const UpdatedProfile = () => {
    if (name.trim() === "") {
      toast.error("Please enter a Name!");
    } else if (email.trim() === "") {
      toast.error("Please enter a Email!");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      toast.error("Invalid Email Address!");
    } else if (date_of_birth.trim() === "") {
      toast.error("Please enter a Date Of Birth!");
    } else if (!(issueDate || "").trim()) {
      toast.error("Please enter a CNIC Issuance!");
    } else if (cnic.trim() === "") {
      toast.error("Please enter a CNIC!");
    } else if (cnic?.length < 13) {
      toast.error("CNIC must be at least 13 digits!");
    } else if (cnic_expiry.trim() === "") {
      toast.error("Please enter a CNIC Expiry!");
    } else {
      setIsloading(true);

      let formData = {
        customerId: authuserdetail?.customer?.customerId,
        dateOfBirth: date_of_birth,
        defaultLoc: authuserdetail?.customer?.defaultLoc,
        cnicExpiryDate: cnic_expiry,
        cnicIssueDate: issueDate,
        cnic: cnic,
        name: name,
        email: email,
      };

      dispatch(UpdateUserInformation(formData, resHandler));
    }
  };

  return (
    <>
      {isLoading ? <Loader /> : <></>}

      <div className="main_order_list_div">
        <div className="main_order_list_tabs_section_div">
          <div className="order_list_tab_active">
            <Link to="My-Profile">My Profile</Link>
          </div>
          <div>
            <Link to="/order-list">Order list</Link>
          </div>
          <div>
            <Link to="/manage-address">Manage Address</Link>
          </div>
          {/* <div>
            <Link to="/Manage-Cards">Manage Cards</Link>
          </div> */}
          <div>
            <Link to="/wallet">Wallet</Link>
          </div>
          <div>
            {" "}
            <Link to="/discount-voucher">Discount Vouchers</Link>
          </div>
        </div>

        <Box
          item
          fixed
          sx={{
            width: "100%",
            backgroundImage: `url('${background_img}')`,
            backgroundSize: "cover",
            display: "flex",
            alignItems: "center",
            backgroundAttachment: "fixed",
          }}
        >
          <Stack direction="column" sx={{ flexGrow: 15, width: "100%" }}>
            <Box>
              <Card
                sx={{
                  width: "100%",
                  borderRadius: 4,
                  boxShadow: 2,
                }}
              >
                <div className="first_main_my_profile_div">
                  <div className="main_my_profile_div">
                    <Stack rowGap={{ xs: 2, sm: 5 }}>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        columnGap={1}
                      >
                        <Avatar />
                        <Typography sx={{ fontSize: "30px", color: "#1591c7" }}>
                          My Profile
                        </Typography>
                      </Stack>
                      <Stack rowGap={5}>
                        <Card
                          sx={{
                            borderRadius: 2,
                            boxShadow: 2,
                            padding: 4,
                            position: "relative",
                            display: "inline-block",
                            textAlign: "center",
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <CardMedia
                              component="img"
                              image={
                                authuserdetail?.base64Image &&
                                authuserdetail?.base64Image !== "NA"
                                  ? authuserdetail?.base64Image
                                  : user__img
                              }
                              alt={
                                authuserdetail?.base64Image &&
                                authuserdetail?.base64Image !== "NA"
                                  ? authuserdetail?.base64Image
                                  : user__img
                              }
                              sx={{
                                borderRadius: "50%",
                                width: { xs: "150px", sm: "250px" },
                                height: { xs: "150px", sm: "250px" },
                              }}
                            />
                            <input
                              accept="image/*"
                              type="file"
                              style={{ display: "none" }}
                              id="profile-image-upload"
                              onChange={handleImageChange}
                            />
                            <label htmlFor="profile-image-upload">
                              <IconButton
                                component="span"
                                sx={{
                                  position: "absolute",
                                  top: "70%",
                                  right: "5%",
                                  transform: "translateY(-50%)",
                                  backgroundColor: "white",
                                  borderRadius: "50px",
                                  border: "1px solid #ccc",
                                  height: "60px",
                                  width: "60px",
                                  "&:hover": {
                                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                                  },
                                }}
                              >
                                <PhotoCameraIcon
                                  sx={{ height: "40px", width: "40px" }}
                                />
                              </IconButton>
                            </label>
                          </div>
                          <Typography
                            sx={{
                              fontSize: 24,
                              textAlign: "center",
                              marginTop: 2,
                            }}
                          >
                            {authuserdetail?.customer?.name?.length > 10
                              ? `${authuserdetail.customer.name.substring(
                                  0,
                                  10
                                )}...`
                              : authuserdetail?.customer?.name}
                          </Typography>
                        </Card>
                        <Button variant="contained" onClick={UpdatedProfile}>
                          Update Profile
                        </Button>
                      </Stack>
                    </Stack>
                    <Stack rowGap={5} sx={{ width: "100%" }}>
                      <FormControl>
                        <FormLabel
                          sx={{
                            fontSize: { xs: ".9rem", sm: "1.2rem" },
                            color: "black",
                            marginBottom: "6px",
                          }}
                        >
                          Name
                        </FormLabel>
                        <TextField
                          size="lg"
                          variant="standard"
                          InputProps={{
                            style: { color: "#05597b" },
                          }}
                          sx={{ fontSize: { xs: "10px", sm: "20px" } }}
                          value={name}
                          onChange={(e) => setname(e.target.value)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          sx={{
                            fontSize: { xs: ".9rem", sm: "1.2rem" },
                            color: "black",
                            marginBottom: "6px",
                          }}
                        >
                          Email
                        </FormLabel>
                        <TextField
                          size="lg"
                          variant="standard"
                          InputProps={{
                            style: { color: "#05597b" },
                          }}
                          sx={{ fontSize: { xs: "10px", sm: "20px" } }}
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          sx={{
                            fontSize: { xs: ".9rem", sm: "1.2rem" },
                            color: "black",
                            marginBottom: "6px",
                          }}
                        >
                          Date Of Birth
                        </FormLabel>
                        <TextField
                          size="lg"
                          variant="standard"
                          type="date"
                          InputProps={{
                            style: { color: "#05597b" },
                          }}
                          sx={{ fontSize: { xs: "10px", sm: "20px" } }}
                          value={date_of_birth}
                          onChange={handleChange}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          sx={{
                            fontSize: { xs: ".9rem", sm: "1.2rem" },
                            color: "black",
                            marginBottom: "6px",
                          }}
                        >
                          CNIC Issuance
                        </FormLabel>
                        <TextField
                          size="lg"
                          variant="standard"
                          type="date"
                          InputProps={{
                            style: { color: "#05597b" },
                          }}
                          sx={{ fontSize: { xs: "10px", sm: "20px" } }}
                          value={issueDate}
                          onChange={(e) => setissueDate(e.target.value)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          sx={{
                            fontSize: { xs: ".9rem", sm: "1.2rem" },
                            color: "black",
                            marginBottom: "6px",
                          }}
                        >
                          CNIC
                        </FormLabel>
                        <TextField
                          type="number"
                          size="lg"
                          variant="standard"
                          InputProps={{
                            style: { color: "#05597b" },
                          }}
                          sx={{ fontSize: { xs: "10px", sm: "20px" } }}
                          value={cnic}
                          onChange={(e) => setcnic(e.target.value.slice(0, 13))}
                        />
                      </FormControl>
                      {cnic && (
                        <p
                          style={{
                            color: "#195389",
                            margin: 0,
                            height: "0px",
                            fontSize: ".8rem",
                            position: "relative",
                            top: "-25px",
                          }}
                        >
                          Note: Ensure your CNIC is valid, as it’s required for
                          your security deposit refund.
                        </p>
                      )}
                      <FormControl>
                        <FormLabel
                          sx={{
                            fontSize: { xs: ".9rem", sm: "1.2rem" },
                            color: "black",
                            marginBottom: "6px",
                          }}
                        >
                          CNIC Expiry
                        </FormLabel>
                        <TextField
                          size="lg"
                          variant="standard"
                          // type="date"
                          type={cnic_expiry !== "Lifetime" ? "date" : "text"}
                          disabled={cnic_expiry === "Lifetime" ? true : false}
                          InputProps={{
                            style: { color: "#05597b" },
                          }}
                          sx={{ fontSize: { xs: "10px", sm: "20px" } }}
                          value={cnic_expiry}
                          onChange={(e) => setcnic_expiry(e.target.value)}
                        />
                      </FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cnic_expiry === "Lifetime"}
                            onChange={(e) =>
                              setcnic_expiry(
                                e.target.checked ? "Lifetime" : null
                              )
                            }
                          />
                        }
                        label="Life Time Expiry"
                      />
                    </Stack>
                  </div>
                </div>
              </Card>
            </Box>
          </Stack>
        </Box>
      </div>
    </>
  );
};
